<template>
    <tr :class="{ 'd-none' : this.hideDefault }">
        <td>{{ group.name }}</td>
        <td v-if="group.defaultVisibility == 1" class="text-success">Ouvert</td>
        <td v-else class="text-danger">Restreint</td>
        <td class="text-center">{{ group.baliseCount }}</td>
        <td>{{ this.dateFormat(group.createdAt) }}</td>
        <td class="text-end">
            <div>
                <a data-bs-toggle="dropdown" href="#" class="btn p-1" aria-expanded="false">
                    <font-awesome-icon :icon="['fas', 'bars']"/>
                </a>
                <div class="dropdown-menu dropdown-menu-end" style="">
                    <a href="#!" v-on:click="this.hideDefault=true;this.hideUpdate=false" class="dropdown-item">Modifier le groupe</a>
                    <a href="#!" v-on:click="deleteGroup(group.id)" class="dropdown-item">Supprimer le groupe</a>
                </div>
            </div>
        </td>
    </tr>
    <tr :class="{ 'd-none' : this.hideUpdate }">
        <td>
            <div class="w-75">
                <input v-model="newName" type="text" class="form-control" placeholder="">
            </div>
        </td>
        <td>
            <div class="form-check form-switch align-middle">
                <input v-model="newVisibility" class="form-check-input" type="checkbox" role="switch" id="visibility" checked>
                <label v-if="this.newVisibility" class="form-check-label text-success" for="visibility">Ouvert</label>
                <label v-else class="form-check-label text-danger" for="visibility">Restreint</label>
            </div>
        </td>
        <td class="text-center">{{ group.baliseCount }}</td>
        <td>{{ this.dateFormat(group.createdAt) }}</td>
        <td>
            <div class="d-flex flex-row-reverse g-3 text-end">
                <div class="mx-3">
                    <button class="btn btn-danger btn-block" v-on:click="this.hideUpdate=true;this.hideDefault=false">Annuler</button>
                </div>
                <div class="mx-3">
                    <button class="btn btn-primary btn-block" v-on:click="this.hideUpdate=true;this.hideDefault=false;updateGroup()">Valider</button>
                </div>
            </div>
        </td>
    </tr>
</template>
        
<script>
import GroupService from '../../services/group.service.js'
import FormatHelper from '../../helpers/format.helper.js'

export default {
    props: ['group'],
    data() {
        return {
            hideDefault: false,
            hideUpdate: true,
            newName: null,
            newVisibility: null
        };
    },
    mounted() {
        this.newName = this.group.name;
        this.newVisibility = this.group.defaultVisibility;
    },
    methods: {
        dateFormat(date) {
            return FormatHelper.formatDate(date);
        },
        deleteGroup(gId) {
            GroupService.deleteGroup(gId)
            .then(() => {
                this.$parent.refreshData();
            })
            .catch(e => {
                console.log(e);
            });
        },
        updateGroup() {
            GroupService.updateGroup(this.group.id, { name: this.newName, defaultVisibility: this.newVisibility })
            .then(() => {
                this.$parent.refreshData();
            })
            .catch(e => {
                console.log(e);
            });
        },
    }
}
</script>