import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL+'alertsms/';

class AlertSMSService {

  getAlertTypes() {
    return axios.get(API_URL + 'getallowedalertsms/', { headers: authHeader() });
  }

  getBaliseAlertRule(baliseId, alertTypeId) {
    return axios.get(API_URL + 'getbaliserule/' + baliseId,
      {
        params: { alertTypeId: alertTypeId },
        headers: authHeader()
      });
  }

  updateRule(baliseId, alertTypeId, active) {
    return axios.put(API_URL + 'updaterule', {
      baliseId: baliseId,
      alertTypeId: alertTypeId,
      active: active
    },
    { headers: authHeader() });
  }

  addRuleNumber(ruleId, numberId) {
    return axios.put(API_URL + 'addrulephonenumber/' + ruleId, { phoneNumberId: numberId }, { headers: authHeader() });
  }

  removeRuleNumber(ruleId, numberId) {
    return axios.put(API_URL + 'removerulephonenumber/' + ruleId, { phoneNumberId: numberId }, { headers: authHeader() });
  }

}

export default new AlertSMSService();