<template>
    <div :class="{ 'closed-panel': !this.panelIsOpen }" class="map-panel">
        <div class="d-flex justify-content-evenly align-items-stretch bg-light">
            <div class="flex-fill d-flex left-border text-center ps-4">
                <div class="align-self-center flex-fill">
                    <div class="row my-1">
                        <div class="col text-center"><BaliseSelect v-if="this.balise" @baliseChange="this.updatedSelectedBalise" :selectedBaliseId="this.selectedBalise.id ? this.selectedBalise.id : this.balise.id" /></div>
                    </div>
                    <div class="row my-1">
                        <div class="col text-center"><DriveSelect :baliseId="this.selectedBalise.id" /></div>
                    </div>
                </div>
            </div>
            <div class="d-flex left-border text-center ps-4 pe-5">
                <div class="align-self-center">
                    <div class="row my-1">
                        <VehicleNameDisplay v-if="this.balise" :balise="this.balise"/>
                    </div>
                </div>
            </div>
            <div class="small-text flex-fill text-center left-border px-4 py-2">
                <div>
                    <small class="fw-bold">Départ </small>
                    <small class="text-muted">{{ this.drive.displayStartDate }}</small>
                </div>
                <div><small>{{ this.drive.startAddress }}</small></div>
                <hr> 
                <div>
                    <small class="fw-bold">Arrivée </small>
                    <small class="text-muted">{{ this.drive.displayEndDate }}</small>
                </div>
                <div><small>{{ this.drive.endAddress }}</small></div>
            </div>
            <div class="flex-fill d-flex left-border text-center ps-4">
                <div class="align-self-center flex-fill">
                    <div class="row my-1">
                        <div class="col text-start"><small class="fw-bold">Durée :</small></div>
                        <div class="col text-start px-1"><small>{{ this.drive.duration }}</small></div>
                    </div>
                    <div class="row my-1">
                        <div class="col text-start"><small class="fw-bold">Distance :</small></div>
                        <div class="col text-start px-1"><small>{{ this.drive.distance }} km</small></div>
                    </div>
                    <div class="row my-1">
                        <router-link :to="'/drivepositions/' + this.drive.id">
                            Voir le détail
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button v-on:click="this.panelIsOpen = !this.panelIsOpen" class="btn btn-primary bottom-pan-button">
        <font-awesome-icon :icon="['fas', !this.panelIsOpen ? 'fa-chevron-down' : 'fa-chevron-up']" size="xl" />
    </button>
</template>



<script>
import BaliseSelect from '../display/BaliseSelect.vue'
import DriveSelect from '../display/DriveSelect.vue'
import VehicleNameDisplay from '../display/VehicleNameDisplay.vue'

export default {
    props: ['balise', 'drive'],
    components: {
        BaliseSelect,
        DriveSelect,
        VehicleNameDisplay
    },
    data() {
        return {
            panelIsOpen: true,
            selectedBalise: {}
        }
    },
    methods: {
        updatedSelectedBalise(baliseChange) {
            this.selectedBalise = baliseChange;
        },
        close() {
            this.panelIsOpen = false;
        }
    }
}
</script>

<style>
.bottom-pan-button {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
    z-index: 400;
}

.small-text {
    font-size: 0.9em;
}

.left-border {
    border-left: 1px solid #adb5bd;
}

.map-panel {
    position: sticky;
    z-index: 400;
}

.closed-panel {
    visibility: hidden;
}
</style>