<template>
    <tr v-if="balise.lastBattery < 40">
        <td>
            <div class="d-flex align-items-center">
                <router-link class="h6 mb-0 lh-1 link-dark link-offset-3 link-underline link-underline-opacity-0 link-underline-opacity-75-hover" :to="'/balise/' + balise.baliseId ">
                    <img :src="require(`../../assets/icons/` + balise.baliseIcon)" class="avatar sm rounded-pill me-3 flex-shrink-0">
                    {{ balise.baliseName }}
                </router-link>
            </div>
        </td>
        <td>
            <BatteryDisplay :battery="balise.lastBattery" />
        </td>
    </tr>
    
</template>


<script>
import BatteryDisplay from '../display/BatteryDisplay.vue'
import BaliseService from '../../services/balise.service.js'

export default {
    props: ['balise', 'groups'],
    components: {
        BatteryDisplay
    },
    data() {
        return {
            hideDefault: false,
            hideName: true,
            hideGroup: true
        };
    },
    methods: {
        updateName(bId) {
            BaliseService.updateName(bId, this.name)
            .then(() => {
                this.$parent.refreshData();
            })
            .catch(e => {
                console.log(e);
            });
        },
        updateGroup(bId) {
            BaliseService.updateGroup(bId, this.group)
            .then(() => {
                this.$parent.refreshData();
            })
            .catch(e => {
                console.log(e);
            });
        },
        changeBalise(bId) {
            this.$emit("param", bId);
        }
    }
}
</script>