<template>
    <div class="d-flex align-items-center">
        <router-link class="mb-0 list-inline" :to="this.linkToMap ? '/home/' + balise.id : '/balise/' + balise.id">
            <div class="list-inline-item align-middle">
                <VehicleIcon :icon="balise.icon"/>
            </div>
            <div class="list-inline-item align-middle">
                <div class="h6 lh-1 text-decoration-underline link-dark link-underline link-underline-opacity-0 link-underline-opacity-75-hover">
                    {{ balise.name }}
                </div>
                <div class="text-muted text-decoration-none small">
                    {{ balise.immat }}
                </div>
                <div v-if="balise.groupId > 0" class="badge bg-secondary text-white text-decoration-none small text-wrap">
                    {{ balise.groupName }}
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import VehicleIcon from '@/components/display/VehicleIcon.vue'
export default {
    components: {
        VehicleIcon
    },
    props: {
    balise: {
      required: true
    },
    linkToMap: {
      default: false
    }
  }
}
</script>