<template>
    <div class="vh-100 vw-100 d-flex flex-column">
        <DefaultHeader/>
        <div class="container-fluid">
            <div class="row main-row">
                <div class="col-auto mh-100">
                </div>
                <div class="col-5 text-center mh-100">
                    <span class="cmd-title">
                        Retour commande
                    </span>
                    <div class="cmd-body cmd-body-font border border-dark overflow-auto">
                        <div v-for="response in this.responses"
                        :key="response.id" class="row bg-light justify-content-between my-1">
                            <div class="col-auto">{{ this.formatDate(response.createdAt) }}</div>
                            <div class="col-auto">{{ response.response }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-3 text-center mh-100">
                    <span class="cmd-title">
                        Commandes envoyées
                    </span>
                    <div class="cmd-body cmd-body-font border border-dark overflow-auto">
                        <div v-for="cmdSent in this.sent"
                        :key="cmdSent.id" class="row bg-light justify-content-between my-1">
                            <div class="col-auto">{{ this.formatDate(cmdSent.updatedAt) }}</div>
                            <div class="col-auto">{{ cmdSent.cmd }}</div>
                            <div class="col-auto align-self-end">
                                <font-awesome-icon @click="this.sendCmd(cmdSent.cmd)" :icon="['fas', 'fa-rotate-right']" flip="horizontal" size="l" class="icon-cmd text-white bg-info rounded-pill p-1 me-2" />
                                <font-awesome-icon v-if="cmdSent.baliseCmdRegisteredId" :icon="['fas', 'fa-thumbtack']" :transform="{ rotate: 45 }" size="l" class="icon-cmd text-dark bg-warning rounded-pill p-1 me-2" />
                                <font-awesome-icon v-else @click="this.createRegisteredCmd(cmdSent.cmd)" :icon="['fas', 'fa-thumbtack-slash']" size="l" class="icon-cmd text-white bg-secondary rounded-pill p-1 me-2" />
                            </div>
                            <div class="text-start">{{ cmdSent.user.email }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-3 text-center mh-100">
                    <span class="cmd-title">
                        Commandes en attente
                    </span>
                    <div class="cmd-body-split cmd-body-font border border-dark overflow-auto">
                        <div v-for="cmd in this.queued"
                        :key="cmd.id" class="row bg-light justify-content-between my-1">
                            <div class="col-auto">{{ this.formatDate(cmd.createdAt) }}</div>
                            <div class="col-auto">{{ cmd.cmd }}</div>
                            <div class="col-auto"></div>
                        </div>
                    </div>
                    <span class="cmd-title mt-3">
                        Commandes <font-awesome-icon :icon="['fas', 'fa-thumbtack']" :transform="{ rotate: 45 }" size="l" class="icon-cmd text-dark bg-warning rounded-pill p-1 me-2" />
                    </span>
                    <div class="cmd-body-split cmd-body-font border border-dark overflow-auto">
                        <div v-for="registered in this.registeredCmds"
                        :key="registered.id"
                        class="text-start bg-light my-1 mx-2">
                            <font-awesome-icon @click="this.sendCmd(registered.cmd)" :icon="['fas', 'fa-play']" size="l" class="icon-cmd text-white bg-success rounded-pill p-1 me-2" />
                            {{ registered.cmd }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row bg-light command-panel">
                <div class="col-6 my-3 text-center">
                    <BaliseSelect @baliseChange="this.changeBalise" :selectedBaliseId="this.watchedBalise ? this.watchedBalise.id : null"></BaliseSelect>
                </div>
                <div class="col-5 text-end my-3">
                    <div v-if="this.watchedBalise != null" class="input-group">
                        <input v-model="cmdToSend" type="text" class="form-control" placeholder="Commande" aria-label="Commande">
                        <button @click="this.sendCmd()" class="btn btn-outline-secondary" type="button">Envoyer</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import BaliseSelect from '../components/display/BaliseSelect.vue'
import CmdService from '../services/cmd.service'
import FormatHelper from '../helpers/format.helper.js'


export default {
    components: {
        DefaultHeader,
        BaliseSelect
    },
    data() {
        return {
            watchedBalise: null,
            queued: {},
            sent: {},
            responses: {},
            registeredCmds: {}
        }
    },
     watch: { 
        watchedBalise: function() {
            if(this.watchedBalise != null) {
                this.getQueued();
                this.getSent();
                this.getResponses();
            }
        }
    },
    created() {
        this.queuedTimer = setInterval(this.getQueued, 5000);
        this.sentTimer = setInterval(this.getSent, 5000);
        this.responsesTimer = setInterval(this.getResponses, 5000);
    },
    mounted() {
        this.getRegisteredCmds();
    },
    beforeUnmount () {
        this.cancelAutoUpdate();
    },
    methods: {
        changeBalise(balise) {
            this.watchedBalise = balise;
        },
        getQueued() {
            if(this.watchedBalise != null) {
                CmdService.getQueued(this.watchedBalise.id, 0, 20)
                .then(response => {
                    this.queued = response.data.rows;
                })
                .catch(e => {
                    console.log(e);
                });
            }
        },
        getSent() {
            if(this.watchedBalise != null) {
                CmdService.getSent(this.watchedBalise.id, 0, 20)
                .then(response => {
                    this.sent = response.data.rows;
                })
                .catch(e => {
                    console.log(e);
                });
            }
        },
        getResponses() {
            if(this.watchedBalise != null) {
                CmdService.getResponses(this.watchedBalise.id, 0, 20)
                .then(response => {
                    this.responses = response.data.rows;
                })
                .catch(e => {
                    console.log(e);
                });
            }
        },
        getRegisteredCmds() {
            CmdService.getRegistered()
                .then(response => {
                    this.registeredCmds = response.data.rows;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        sendCmd(cmd = null) {
            if(this.watchedBalise != null) {
                CmdService.addCmd(this.watchedBalise.id, cmd == null ? this.cmdToSend : cmd)
                .then(() => {
                    this.getQueued();
                })
                .catch(e => {
                    console.log(e);
                });
            }
        },
        createRegisteredCmd(cmd) {
            CmdService.addCmdRegistered(cmd)
                .then(() => {
                    this.getSent();
                    this.getRegisteredCmds();
                })
                .catch(e => {
                    console.log(e);
                });
        },
        formatDate(date) {
            return FormatHelper.formatDate(date);
        },
        cancelAutoUpdate () {
            clearInterval(this.queuedTimer);
            clearInterval(this.sentTimer);
            clearInterval(this.responsesTimer);
        }
    }
}
</script>


<style>
.commande-panel {
    position: fixed;
    bottom: 0px;
}

.main-row {
    height: 70vh;
}

.cmd-title {
    height: 7%;
    display: inline-block;
}

.cmd-body-font {
    font-size: 0.75em;
}

.cmd-body {
    max-height: 93%;
    height: 93%;
    overflow-x: hidden !important;
}

.cmd-body-split {
    max-height: 40%;
    height: 40%;
    overflow-x: hidden !important;
}

.icon-cmd {
    cursor: pointer;
    height: 12px;
    width: 12px;
}
</style>