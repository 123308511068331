<template>
    <ul class="pagination">
        <li class="pagination-item page-item" :class="{ disabled: isInFirstPage }">
            <button type="button" class="page-link" @click="onClickFirstPage" :disabled="isInFirstPage">Première Page</button>
        </li>
        
        <li class="pagination-item page-item ms-2" :class="{ disabled: isInFirstPage }">
            <button type="button" class="page-link" @click="onClickPreviousPage" :disabled="isInFirstPage"> &lt; </button>
        </li>

        <li v-for="page in pages" :key="page.name" class="pagination-item" :class="{ active: page.name == currentPage }">
            <button type="button" class="page-link" @click="onClickPage(page.name)" :disabled="page.isDisabled">{{ page.name }}</button>
        </li>
        
        <li class="pagination-item page-item me-2" :class="{ disabled: isInLastPage }">
            <button type="button" class="page-link" @click="onClickNextPage" :disabled="isInLastPage"> &gt; </button>
        </li>

        <li class="pagination-item page-item" :class="{ disabled: isInLastPage }">
            <button type="button" class="page-link" @click="onClickLastPage" :disabled="isInLastPage">Dernière Page</button>
        </li>
    </ul>
</template>
<script>
export default {
    props: {
        maxVisibleButtons: {
            type: Number,
            required: false,
            default: 3
        },
        totalPages: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        }
    },
    computed: {
        
        startPage(){
            
            if (this.currentPage === 1){
                return 1
            }

            if(this.currentPage === this.totalPages){
                return this.totalPages - this.maxVisibleButtons +1
            }

            return this.currentPage - 1
        },

        pages(){
            const range = []

            for (let i=this.startPage; i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages); i++){
                if(i != 0){
                    range.push({
                        name: i,
                        isDisabled: i === this.currentPage
                    })
                }
            }

            return range
        },

        isInFirstPage() {
            return this.currentPage === 1
        },

        isInLastPage() {
            return this.currentPage === this.totalPages
        }
    },
    methods: {
        onClickFirstPage() {
            this.$emit('pagechanged', 1)
        },
        onClickPreviousPage() {
            this.$emit('pagechanged', this.currentPage - 1)
        },
        onClickPage(page){
            this.$emit('pagechanged', page)
        },
        onClickNextPage(){
            this.$emit('pagechanged', this.currentPage + 1)
        },
        onClickLastPage(){
            this.$emit('pagechanged', this.totalPages)
        },
        isPageActive(page){
            return this.currentPage === page
        }
    }
}
</script>

<style>
.pagination {
    list-style-type: none;
}

.pagination-item {
    display: inline-block;
}

.active {
    background-color: #4AAE9B;
    color: #ffffff;
}
</style>