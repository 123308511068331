<template>
    <DefaultHeader />

        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-12">
                    <div class="card shadow my-2 p-3 bg-white rounded">
                        <div class="row d-flex justify-content-around align-items-center">
                            
                            <div class="col col-md-4 my-1">
                                <VehicleNameDisplay :balise="baliseInfo"></VehicleNameDisplay>
                            </div>
                            
                            <div class="col col-md-5 my-1">
                                <form>
                                    <h5 class="text-center">Filtrer par date : </h5>
                                    <div class="d-flex">
                                        <div class="input-group me-1">
                                            <span class="input-group-text bg-secondary-subtle">Début : </span>
                                            <input class="form-control" type="date" v-model="startDate" required>
                                        </div>
                                        <div class="input-group me-1">
                                            <span class="input-group-text bg-secondary-subtle">Fin : </span>
                                            <input class="form-control" type="date" v-model="endDate" required>
                                        </div>
                                        <button class="btn btn-primary" type="button" @click="this.$refs.positionsListRef.changeFilters(this.startDate, this.endDate)">Ok</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <PositionsList 
            ref="positionsListRef"
            :baliseId="this.$route.params.id" />
        </div>

    <DefaultFooter />
</template>

<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'
import PositionsList from '../components/PositionsList.vue'
import FormatHelper from '../helpers/format.helper.js'
import BaliseService from '../services/balise.service'
import VehicleNameDisplay from '../components/display/VehicleNameDisplay.vue'


export default {
    components: {
        DefaultHeader,
        DefaultFooter,
        PositionsList,
        VehicleNameDisplay

    },
    data() {
        return {
            baliseInfo: []
        }
    },
    computed: {
        baliseIcon: function () {
            return {
                icon: this.baliseInfo.icon && require(`../assets/icons/${this.baliseInfo.icon}`)
            }
        }
    },
    mounted() {
        this.getBaliseInfos()
    },
    methods: {
        getBaliseInfos() {
            BaliseService.getBaliseInfo(this.$route.params.id)
                .then(response => {
                    this.baliseInfo = FormatHelper.baliseInfoFormat(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }
}
</script>