import axios from 'axios';
import authHeader from './auth-header';
import UserHelper from '../helpers/user.helper.js'

const API_URL = process.env.VUE_APP_API_URL+'group/';

class GroupService {
  getClientGroups() {
    return axios.get(API_URL + 'getclientgroups/' + UserHelper.getClientId(), { headers: authHeader() });
  }

  createGroup(name) {
    return axios.put(API_URL + 'createclientgroup/' + UserHelper.getClientId(), {
      name: name
    },
    { headers: authHeader() });
  }

  deleteGroup(groupId) {
    return axios.delete(API_URL + 'delete/' + groupId, { headers: authHeader() });
  }

  updateGroup(groupId, data) {
    return axios.put(API_URL + 'update/' + groupId, data, { headers: authHeader() });
  }
}

export default new GroupService();