<template>
    <DefaultHeader :noMargin="true"/>
    <ReportPDFDrives :baliseId="this.baliseId"/>
    <DefaultFooter/>
</template>
<script>
import DefaultHeader from '@/components/DefaultHeader.vue'
import DefaultFooter from '@/components/DefaultFooter.vue';
import ReportPDFDrives from '@/components/pdf/drives/ReportPDFDrivesComponent.vue'

export default {
    components: {
        DefaultHeader,
        DefaultFooter,
        ReportPDFDrives
    },
    data() {
        return {
            baliseId: this.$route.params.id,
        }
    },
    onMounted(){

    }
}
</script>
<style scoped>
.active {
    color: black;
    background-color: #FFFFFF;
}
</style>