<template>
    <DefaultHeader />
    <div class="form-group">
        <input v-model="latitude" type="text" class="form-control" placeholder="latitude">
        <input v-model="longitude" type="text" class="form-control" placeholder="longitude">
        <button class="btn btn-primary btn-block" @click="geocoding()"><font-awesome-icon
                :icon="['fas', 'circle-plus']" /> Geocoding</button>
    </div>
    <div>
        {{ this.address }}
    </div>
    <DefaultFooter />
</template>
    
<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'
import GeocodingService from '../services/geocoding.service.js'


export default {
    components: {
        DefaultHeader,
        DefaultFooter
    },
    data() {
        return {
            address: "Adresse"
        };
    },
    methods: {
        geocoding() {
            GeocodingService.getAddress(this.latitude, this.longitude)
            .then(response => {
                if(response.data.features.length >= 2) {
                    this.address = response.data.features[1].properties.label;
                    return response.data.features[1].properties.label;
                }
                else {
                    this.address = response.data.features[0].properties.label;
                    return response.data.features[0].properties.label;
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }
}
</script>