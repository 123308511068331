<template>
    <div :style="{ color: markerColor }" class="markerShadow">
        <font-awesome-icon :icon="['fas', 'fa-location-arrow']" :transform="{ rotate: this.position.direction-45 }" size="xl" />
    </div>
</template>

<script>
export default {
    props: ['position'],
    computed: {
    markerColor: function () {
        if(this.position.speed < 51)
            return "#07ed4c"
        if(this.position.speed < 91)
            return "yellow"
        if(this.position.speed < 131)
            return "#ff9a03"
        return "#ff0303"
    }
  }
}
</script>

<style>
.markerShadow {
    filter:drop-shadow(1px 1px 1px black)
}
</style>