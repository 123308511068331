<template>
    <div v-if="this.dIn && this.dIn.activated">
        <span :style="{ color: dInColor }">
            <font-awesome-icon :icon="['fas', 'circle']" size="l" />
        </span>
        <span class="ms-2">
            {{ this.dIn.name }}
        </span>
    </div>
</template>

<script>
export default {
    props: ['dIn'],
    computed: {
        dInColor: function () {
            if(this.dIn.value)
                return "#07ed4c"
            else
                return "#ff0303"
        }
    }
}
</script>