<template>
    <DefaultHeader :noMargin="true"/>
    <div class="container-fluid">
        <div class="row" style="height: 25vh;">
            <div class="d-flex justify-content-center align-items-center bg-light mb-4">
                <h1>Mises à jour</h1>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="row d-flex">
                <div class="col-12 col-md-2 border-end pt-5">
                    <nav>
                        <div class="p-4">
                            <h6>Versions :</h6>
                            <ul class="list-group">
                                <li v-for="(patch, index) in patchesNotes.slice().reverse()" :key="index" class="list-group-item"><a :href="'#'+patch.version">Version {{ patch.version }}</a><br>
                                    <small class="d-block text-muted"> {{ $filters.timeAgo(new Date(patch.date)) }}</small></li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div class="col-12 col-md-10 d-flex justify-content-center pt-5">
                    <div class="col-10 col-md-6 pt-3 pb-2">
                        <div v-for="(patch, index) in patchesNotes.slice().reverse()" :key="index">
                            <div :id="patch.version" class="p-2">
                                <h2>Version : {{ patch.version }} - {{ patch.title }}</h2>
                                <p>{{ patch.description }}</p>
                                <ul class="mb-5">
                                    <li v-for="(item, index) in patch.items" :key="index">
                                        <span v-if="item.text">{{ item.text }}</span>
                                        <span v-else>{{ item }}</span>
                                        <img v-if="item.image" v-bind:src="require(`../assets/` + item.image)" class="img-fluid" />
                                    </li>
                                </ul>
                                <small class="d-block mb-3 text-muted">Date : {{ new Date(patch.date).toLocaleDateString('fr-FR') }}</small>
                                <div class="my-4">
                                    <hr class="hr" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <DefaultFooter/>
</template>

<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'


export default {
    components: {
        DefaultHeader,
        DefaultFooter
    },
    mounted() {
        localStorage.setItem('lastSeenPatchnote', this.patchesNotes[this.patchesNotes.length - 1].version);
    },
    data(){
        return {
            patchesNotes: [
                {
                    date: '2024-04-03',
                    title: 'Préproduction',
                    version: '0.1',
                    description: 'Améliorations et corrections de bugs mineurs : ',
                    items: [
                        'Correction de la pagination sur la liste des positions',
                        'Supression du lien vers les offres sécurité',
                        'Correction de bugs mineurs'
                    ]
                },
                {
                    date: '2024-04-17',
                    title: 'Préproduction',
                    version: '0.2',
                    description: 'Cette version comporte une nouvelle page "Mises à jour" et plusieurs améliorations de pages déjà existantes : ',
                    items: [
                        'Ajout de la page "Mises à jour"',
                        "Changement de la librairie d'icônes",
                        'Amélioration du système de pagination'
                    ]
                },
                {
                    date: '2024-05-10',
                    title: 'Préproduction',
                    version: '0.3',
                    description: 'Ajout des trajets sur la page balise : ',
                    items: [
                        'Ajout des trajets',
                        "Modifications des filtres pour l'export PDF"
                    ]
                },
                {
                    date: '2024-05-16',
                    title: 'Préproduction',
                    version: '0.5',
                    description: 'Ajout de la balise Topfly : ',
                    items: [
                        "Gestion d'un nouveau type de balise : Topfly",
                        "Gestion multi-clients",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-05-29',
                    title: 'Préproduction',
                    version: '0.6',
                    description: 'Améliorations générales : ',
                    items: [
                        "Amélioration de la gestion des utilisateurs",
                        "Ajout de la liste des positions pour les trajets",
                        "Ajout des dernières alertes sur la page véhicule",
                        "Nouvelle page Contact",
                        "Nouvelle page FAQ",
                        "Refonte de la page Connexion",
                        "Amélioration globale de l'interface",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-06-24',
                    title: 'Préproduction et gestion des alertes',
                    version: '0.7',
                    description: 'Mise à jour importante. Ajout de la gestion des alertes SMS, améliorations générales : ',
                    items: [
                        "Ajout de la gestion des alertes SMS sur la page véhicule",
                        "Nouveaux icônes de véhicules",
                        "Affichage de l'échelle sur la carte",
                        "Nouvelle adresse e-mail pour le support",
                        "Améliorations d'interface divers",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-06-26',
                    title: 'Patch correctif',
                    version: '0.8',
                    description: 'Cette version corrige les derniers problèmes utilisateur remontés. Elle améliore également différents interfaces.',
                    items: [
                        "Ajout d'une numérotation pour les positions d'un trajet",
                        "Affichage des positions d'un trajet dans l'ordre chronologique",
                        "Les heures s'affichent maintenant au format local de l'utilisateur plutôt qu'au format GMT (évite le décalage horaire à la visualisation depuis la France)",
                        "Correction de l'affichage de la vitesse des positions lorsque celle-ci vaut 0 km/h",
                        "Correction du changement de nom sur la page véhicule",
                        "Améliorations d'interface divers",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-07-02',
                    title: 'Préproduction',
                    version: '0.9',
                    description: 'Cette version active l\'envoi des SMS pour les alertes. Elle contient également plusieurs améliorations et nouvelles fonctionnalités.',
                    items: [
                        "Augmentation du zoom possible sur la carte",
                        "Ajout du suivi des véhicules",
                        "Changement du titre de la plateforme dans l'onglet du navigateur",
                        "Activation des alertes SMS",
                        "Ajout de nouvelles données pour les positions, niveaux de réception GPS et GSM",
                        "Mise à jour des icônes de véhicule",
                        "Correction du choix des icônes de véhicule",
                        "Améliorations d'interface divers",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-07-08',
                    title: 'Préproduction',
                    version: '0.10',
                    description: 'Cette version ajoute la possibilité de visionner les trajets passés sur la carte. Elle améliore également les informations de l\'historique des trajets et corrige des problèmes utilisateur.',
                    items: [
                        "Ajout du visionnage des trajets depuis la liste des trajets d'un véhicule",
                        "Ajoute la durée des trajets",
                        "Correction de l'affichage des alertes par véhicule",
                        "Correction des informations GPS",
                        "Correction des dates affichées dans certaines interfaces",
                        "Améliorations d'interface divers",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-07-11',
                    title: 'Préproduction',
                    version: '0.11',
                    description: "Cette version améliore les interfaces sur différentes pages et corrige divers problèmes d'affichage",
                    items: [
                        "Modifie la couleur des trajets affichés sur la carte",
                        "Amélioration de la navigation pour les administrateurs",
                        "Affiche l'IMEI sur la page véhicule",
                        "L'affichage des trajets sur la carte est maintenant centrée",
                        "Correction des heures affichées dans l'historique des trajets",
                        "Correction du problème des véhicules qui mettaient du temps à s'afficher sur la carte",
                        "Améliorations d'interface divers",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-07-16',
                    title: 'Préproduction',
                    version: '0.12',
                    description: "Cette version corrige les problèmes et améliore l'envoi de SMS. Elle améliore également plusieurs éléments de navigation.",
                    items: [
                        "Modification du logo",
                        "L'historique des trajets sur la carte est amélioré",
                        "Ajout de plusieurs données sur la page véhicule",
                        "La configuration de SMS est maintenant réservée aux admin",
                        "Modification des noms de rôles utilisateur",
                        "Correction du zoom minimum sur la carte",
                        "Correction de l'envoi des SMS",
                        "Améliorations d'interface divers",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-07-24',
                    title: 'Avant lancement V1',
                    version: '0.13',
                    description: "Cette version corrige les derniers retours utilisateurs avant la V1.",
                    items: [
                        "Modification du logo",
                        "Ajoute un panneau de navigation dans l'historique des trajets",
                        "Améliore l'ergonomie de la carte pour les clients n'ayant qu'un seul véhicule",
                        "L'heure des alertes SMS est maintenant sur le créneau français",
                        "Ajoute des données à l'affichage sur les véhicules",
                        "Ajoute l'IMEI aux rapportds PDF",
                        "Correction du mode suivi d'un véhicule sur la carte",
                        "Correction du niveau de batterie affiché pour les alertes",
                        "Améliorations d'interface divers",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-07-30',
                    title: 'Lancement V1',
                    version: '1.0',
                    description: "V1 ouverte aux clients. Amélioration globale de la plateforme.",
                    items: [
                        "Les alertes sont maintenant visibles dans les listes de positions",
                        "Ajout de liens vers Google map",
                        "Modification de l'affichage sur la page véhicule",
                        "Correction de la page véhicule en navigation mobile",
                        "Améliorations d'interface divers",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-08-02',
                    title: 'Gestion des données',
                    version: '1.1',
                    description: "Ajout de nouvelles données et multiples petites corrections.",
                    items: [
                        "Amélioration de l'interface d'export de rapport PDF",
                        "Ajoute la température aux données des positions",
                        "Ajout de la plaque d'immatriculation pour les véhicules",
                        "Les liens des trajets de la page véhicule sont maintenant cliquables",
                        "Améliorations d'interface divers",
                        "Correction de l'affichage des alertes dans la liste des trajets",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-08-06',
                    title: 'Graphes de contrôle',
                    version: '1.2',
                    description: "Ajout des graphes de contrôle en prévision du futur dashboard.",
                    items: [
                        "Ajout du graphe de températures pour les trajets (page trajet)",
                        "Ajout du graphe de vitesses pour les trajets (page trajet)",
                        "Améliorations d'interface divers",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-09-16',
                    title: 'Nouvelles fonctionnalités',
                    version: '1.3',
                    description: "Ajout de la recherche par véhicule et corrections diverses.",
                    items: [
                        "Ajout de la recherche de véhiucles (nom ou immatriculation) sur la page liste des véhicules",
                        "Le nom du groupe s'affiche maintenant sous le nom du véhicule",
                        "Amélioration de l'affichage pour les informations manquantes",
                        "Gestion de nouveaux systèmes de géolocalisation",
                        "Les rapports PDF n'affichent maintenant qu'en page 1 le titre du rapport",
                        "Améliorations d'interface divers",
                        "Correction empêche l'ajout de groupe sans nom",
                        "Correction du filtre de dates pour l'affichage des positions",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-09-20',
                    title: 'Refonte de la barre de navigation',
                    version: '1.4',
                    description: "Refonte de la barre de navigation dans le header",
                    items: [
                        "Ajout des notifications",
                        { text: "Refonte visuelle des liens de navigation", image: "navbar-change.png" }
                    ]
                },
                {
                    date: '2024-09-21',
                    title: 'Amélioration de la carte',
                    version: '1.5',
                    description: "Ajoute la possibilité de contrôler les alertes textos depuis la carte.",
                    items: [
                        "Il est maintenant possible d'activer et désactiver les alertes textos des véhicules depuis la carte",
                        "L'utilisateur est maintenant redirigé sur la page demandée après la connexion",
                        "Amélioration de la sécurité"
                    ]
                },
                {
                    date: '2024-09-28',
                    title: "Amélioration de l'interface",
                    version: '1.6',
                    description: "Amélioration globale de l'interface de navigation.",
                    items: [
                        "Affichage des adresses de départ et d'arrivée des trajets sur la page véhicule",
                        "Affichage de la dernière température reçue sur les écrans des véhicules",
                        "Modification de la navigation, les visiteurs ne peuvent plus modifier les informations des véhicules",
                        "Modification de la navigation pour les opérateurs",
                        "Correction et amélioration du menu en version mobile"
                    ]
                },
                {
                    date: '2024-10-08',
                    title: "Mise à jour des alertes",
                    version: '1.7',
                    description: "Amélioration du nombre d'alertes gérées.",
                    items: [
                        "Gestion de nouvelles alertes, notamment sur la conduite brutale",
                        "Affichage des alertes sur la carte de visualisation des trajets",
                        "Ajout de la télésurveillance en lien avec les centres de télésurveillance",
                        "Ajout de la gestion de visibilité pour les groupes",
                        "Amélioration de la modification des groupes",
                        "Mise à jour de sécurité"
                    ]
                },
                {
                    date: '2024-10-08',
                    title: "Mise à jour d'interface",
                    version: '1.8',
                    description: "Amélioration et corrections des interfaces sur plusieurs pages.",
                    items: [
                        "Suppression de la colonne Groupe dans l'affichage des véhicules",
                        "Nouveaux icônes pour les alertes sur l'historique des trajets",
                        "Améliorations globales de l'interface",
                        "Correction de la sélection des dates sur mobile",
                        "Correction de l'affichage des véhicules sans icône"
                    ]
                },
                {
                    date: '2024-10-21',
                    title: "Refonte des trajets et envois de commandes",
                    version: '1.9',
                    description: "Refonte de l'affichage et de la navigation dans les trajets et gestion des envois de commandes aux véhicules pour SMS",
                    items: [
                        "Modification de l'affichage des trajets sur la carte",
                        "Affichage du panneau de contrôle par défaut ouvert sur les historiques de trajets",
                        "Ajout de liens entre les différentes pages de trajets",
                        "Amélioration de la page listant les positions des trajets",
                        "Ajout de panneau de contrôle sur la carte des véhicules",
                        "Ajout de l'envoi de commandes aux véhicules",
                        "Améliorations globales de l'interface",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-10-23',
                    title: "Amélioration du patch précédent",
                    version: '1.10',
                    description: "Amélioration du panneau de contrôle de la carte et de l'envoi de commandes",
                    items: [
                        "Ajout de nouvelles information dans le panneau de contrôle de la carte",
                        "Correction de l'ouverture / fermeture du panneau de contrôle de la carte",
                        "Correction de l'affichage des commandes sauvegardées pour un grand nombre de commandes",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-10-24',
                    title: "Améliorations de navigation",
                    version: '1.11',
                    description: "Amélioration du panneau de contrôle des historiques et de l'envoi de commandes",
                    items: [
                        "Ajout d'information et mise à jour du panneau de contrôle dans la visualisation des trajets",
                        "Ajout du login d'utilisateur dans les commandes envoyées",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-11-08',
                    title: "Ajout des informations d'état des véhicules",
                    version: '1.12',
                    description: "Gestion des entrées de balises pour connaître les états des véhicules, améliorations générales des fonctionnalités déjà présentes",
                    items: [
                        "Les véhicules n'ayant pas émis de données depuis plus de 24h sont maintenant surlignés en rouge dans la liste des véhicules",
                        "Ajout de la date de dernière connexion pour les utilisateurs, visible sur la page de gestion des utilisateurs",
                        "Amélioration de la FAQ",
                        "Ajout d'une page de gestion de tous les véhicules destinée aux agents SMS",
                        "Corrections diverses"
                    ]
                },
                {
                    date: '2024-11-21',
                    title: "Amélioration des informations de véhicules",
                    version: '1.13',
                    description: "Gestion de l'intégralité des entrées de balises pour connaître les états des véhicules",
                    items: [
                        "Ajout de la page configuration des balises pour les supers admins",
                        "Affichage des états des véhicules sur la page véhicule",
                        "Modification de l'affichage des modèles de balises",
                        "Corrections de textes dans l'interface",
                        "Correction d'une erreur d'affichage pour les trajets n'ayant pas de coordonnées",
                        "Corrections mineures"
                    ]
                },
                {
                    date: '2024-12-02',
                    title: "Ajout d'icônes",
                    version: '1.14',
                    description: "Ajout d'icônes pour les motos",
                    items: [
                        "Ajout de nouveaux icônes"
                    ]
                },
                {
                    date: '2024-12-04',
                    title: "Amélioration d'interface",
                    version: '1.15',
                    description: "Amélioration générale de l'interface sur plusieurs pages d'Argos",
                    items: [
                        "Affichage de l'offre commerciale dans la page véhicule",
                        "Affichage en temps réel des informations dans le panneau de contrôle de la carte",
                        "Le lien sur le nom du véhicule depuis la page véhicule mène directement sur la carte",
                        "Modification des icônes motos et scooter"
                    ]
                },
                {
                    date: '2024-12-04',
                    title: "Correction de la version précédente",
                    version: '1.15.1',
                    description: "Correction de la dernière mise à jour",
                    items: [
                        "Correction du rafraîchissement du panneau de la carte en mode suivi de véhicule"
                    ]
                },
                {
                    date: '2024-12-09',
                    title: "Ajout de la facturation",
                    version: '1.16',
                    description: "Version introduisant la fonctionnalité facturation pour le personnel de SMS, accompagné de corrections mineures",
                    items: [
                        "Ajout de l'interface facturation pour le personnel SMS",
                        "Les noms des véhicules ont maintenant un fond pour les rendre plus lisibles depuis la carte",
                        "Correction de la visibilité des groupes",
                        "Corrections et améliorations mineures"
                    ]
                },
            ]
        }
    },
    methods: {

    }
}
</script>