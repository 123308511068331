<template>
    <DefaultHeader/>
    <div class="container-fluid">
        <div class="row">
            <div class="col-1">
            </div>
            <div class="col-3">
                <BaliseSelect @baliseChange="this.changeBalise" :selectedBaliseId="this.watchedBalise ? this.watchedBalise.id : null" ref="select"/>
            </div>
        </div>

        <div v-if="this.watchedBalise != null" class="row mt-3">
            <div class="col-1">
            </div>
            <div class="col-3">
                <VehicleNameDisplay :balise="this.watchedBalise"/>
            </div>
            <div class="col-6">
                <TableRow :key="this.dIn1Key" @dInChange="(value, name) => { this.dInUpdate(1, value, name) }" :dIn="this.watchedBalise.dIn1" />
                <TableRow :key="this.dIn2Key" @dInChange="(value, name) => { this.dInUpdate(2, value, name) }" :dIn="this.watchedBalise.dIn2" />
                <TableRow :key="this.dIn3Key" @dInChange="(value, name) => { this.dInUpdate(3, value, name) }" :dIn="this.watchedBalise.dIn3" />
                <TableRow :key="this.dIn4Key" @dInChange="(value, name) => { this.dInUpdate(4, value, name) }" :dIn="this.watchedBalise.dIn4" />
                <TableRow :key="this.dIn5Key" @dInChange="(value, name) => { this.dInUpdate(5, value, name) }" :dIn="this.watchedBalise.dIn5" />
                <TableRow :key="this.dIn6Key" @dInChange="(value, name) => { this.dInUpdate(6, value, name) }" :dIn="this.watchedBalise.dIn6" />
            </div>
        </div>
    </div>
</template>

<script>
import BaliseService from '../services/balise.service'
import DefaultHeader from '../components/DefaultHeader.vue'
import BaliseSelect from '../components/display/BaliseSelect.vue'
import TableRow from '../components/baliseConfigPage/TableRow.vue'
import VehicleNameDisplay from '../components/display/VehicleNameDisplay.vue'

export default {
    components: {
        DefaultHeader,
        BaliseSelect,
        VehicleNameDisplay,
        TableRow
    },
    data() {
        return {
            watchedBalise: null,
            baliseInfo: {},
            dIn1Key: 0,
            dIn2Key: 0,
            dIn3Key: 0,
            dIn4Key: 0,
            dIn5Key: 0,
            dIn6Key: 0
        }
    },
    methods: {
        changeBalise(balise) {
            this.watchedBalise = balise;
            this.dIn1Key += 1;
            this.dIn2Key += 1;
            this.dIn3Key += 1;
            this.dIn4Key += 1;
            this.dIn5Key += 1;
            this.dIn5Key += 1;
        },
        dInUpdate(dInNumber, value, name) {

            let dInData;
            switch (dInNumber) {
                case 1:
                    dInData = { dIn1Activated: value, dIn1Name: name };
                break;
                case 2:
                    dInData = { dIn2Activated: value, dIn2Name: name };
                break;
                case 3:
                    dInData = { dIn3Activated: value, dIn3Name: name };
                break;
                case 4:
                    dInData = { dIn4Activated: value, dIn4Name: name };
                break;
                case 5:
                    dInData = { dIn5Activated: value, dIn5Name: name };
                break;
                case 6:
                    dInData = { dIn6Activated: value, dIn6Name: name };
                break;
            }

            BaliseService.updateData(this.watchedBalise.id, dInData)
            .then(() => {
                this.$refs.select.getBalises()
            })
            .catch(e => {
                console.log(e);
            });
        }
    }
}
</script>