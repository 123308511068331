<template>
    <div v-if="isLargeScreen" :class="{ 'closed-panel': !this.panelIsOpen }" class="map-panel">
        <div class="d-flex justify-content-evenly align-items-stretch bg-light">
            <div class="flex-fill align-self-center text-center ms-5 ps-5">
                <VehicleNameDisplay :balise="selectedBalise"/>
                <div v-if="!this.followMode" class="mt-2 text-start">
                    <font-awesome-icon v-on:click="followBalise()" :icon="['fas', 'fa-play']" size="l" class="circle-awesome-font text-white bg-success rounded-pill p-1 me-1" />
                    Suivre
                </div>
                <div v-else class="mt-2 text-start">
                    <font-awesome-icon v-on:click="unFollowBalise()" :icon="['fas', 'fa-stop']" size="l" class="circle-awesome-font text-white bg-danger rounded-pill p-1 me-1" />
                    Arrêter suivi
                </div>
            </div>
            <div class="flex-fill left-border ps-4 py-2">
                Mise à jour : {{ $filters.timeAgo(selectedBalise.lastSeen) }}
                <br>{{ selectedBalise.lastAddress }}
                <br><router-link :to="'/drives/' + selectedBalise.id">Historique</router-link>
                <br><LatLongDisplay :latitude="selectedBalise.lastLatitude" :longitude="selectedBalise.lastLongitude"/>
            </div>
            <div class="flex-fill d-flex left-border text-center ps-4">
                <div class="align-self-center flex-fill">
                    <div class="row my-1">
                        <div class="col text-start">Batterie :</div>
                        <div class="col text-start px-1"><BatteryDisplay :battery="selectedBalise.lastBattery"/></div>
                    </div>
                    <div class="row my-1">
                        <div class="col text-start">Alimentation : </div>
                        <div class="col text-start px-1"><AlimDisplay :alim="selectedBalise.lastPowerVoltage"/></div>
                    </div>
                    <div class="row my-1">
                        <div class="col text-start">Temp. système : </div>
                        <div class="col text-start px-1"><TemperatureDisplay :temperature="selectedBalise.lastTemperature"/></div>
                    </div>
                </div>
            </div>
            <div class="flex-fill d-flex left-border text-center ps-4">
                <div class="align-self-center flex-fill">
                    <div class="row my-1">
                        <div class="col text-start">Vitesse :</div>
                        <div class="col text-start px-1">{{ selectedBalise.lastSpeed }} km/h</div>
                    </div>
                    <div class="row my-1">
                        <div class="col text-start">GSM : </div>
                        <div class="col text-start px-1"><GSMDisplay :gsm="selectedBalise.lastLevelGSM"/></div>
                    </div>
                    <div class="row my-1">
                        <div class="col text-start">GPS : </div>
                        <div class="col text-start px-1"><GPSDisplay :gps="selectedBalise.lastLevelGPS"/></div>
                    </div>
                </div>
            </div>
            <div class="flex-fill d-flex left-border text-center ps-4">
                <div class="align-self-center">
                    <div v-if="this.selectedBalise.allowedSMS" class="form-check form-switch">
                        <input v-model="this.smsStatus" v-on:change="updateBaliseSMSStatus()" class="form-check-input"
                        type="checkbox" role="switch" id="allAlerts" checked>
                        <label class="form-check-label" for="allAlerts">Alertes textos</label>
                    </div>
                </div>
            </div>
            <div class="text-end">
                <button v-on:click="this.panelIsOpen = false" class="btn btn-primary mt-2 mx-4">
                    <font-awesome-icon :icon="['fas', 'fa-xmark']" size="xl" />
                </button>
            </div>
        </div>
    </div>
    <div v-on:click="this.close()" class="outside"></div>
</template>



<script>
import BaliseService from '../../services/balise.service'
import BatteryDisplay from '../display/BatteryDisplay.vue'
import GSMDisplay from '../display/GSMDisplay.vue'
import GPSDisplay from '../display/GPSDisplay.vue'
import AlimDisplay from '../display/AlimDisplay.vue'
import TemperatureDisplay from '../display/TemperatureDisplay.vue'
import VehicleNameDisplay from '../display/VehicleNameDisplay.vue'
import LatLongDisplay from '../display/LatLongDisplay.vue'

export default {
    props: ['followMode'],
    components: {
        BatteryDisplay,
        GSMDisplay,
        AlimDisplay,
        GPSDisplay,
        TemperatureDisplay,
        VehicleNameDisplay,
        LatLongDisplay
    },
    data() {
        return {
            selectedBalise: {},
            panelIsOpen: false,
            smsStatus: null,
            isLargeScreen: false
        }
    },
    mounted() {
        this.updateScreenSize();
        window.addEventListener('resize', this.updateScreenSize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateScreenSize);
    },
    methods: {
        open(balise) {
            this.selectedBalise = balise;
            this.panelIsOpen = true;
        },
        refresh(balises) {
            balises.forEach(balise => {
                if(this.selectedBalise.id == balise.id)
                    this.selectedBalise = balise;
            });
        },
        close() {
            this.panelIsOpen = false;
        },
        updateScreenSize() {
            this.isLargeScreen = window.innerWidth > 900;
        },
        followBalise() {
            this.$emit("bId", this.selectedBalise.id);
        },
        unFollowBalise() {
            this.$router.push('/');
        },
        updateBaliseSMSStatus() {
            BaliseService.updateData(this.selectedBalise.id, { alertSMS: this.smsStatus });
        }
    }
}
</script>

<style>
.side-borders {
    border-right: 1px solid #adb5bd;
    border-left: 1px solid #adb5bd;
}

.map-panel {
    position: sticky;
    border-bottom: 1px solid #adb5bd;
    z-index: 400;
}

.left-border {
    border-left: 1px solid #adb5bd;
}

.closed-panel {
    visibility: hidden;
}

.circle-awesome-font {
    cursor: pointer;
    height: 12px;
    width: 12px;
}

.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 230px;
  left: 0px;
}
</style>