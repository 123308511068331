<template>
    <div v-bind:class="{'mb-3': !noMargin}" class="navbar navbar-expand-md navbar-light bg-white border-bottom box-shadow header-z">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img v-bind:src="require('../assets/sms-logo.png')" class="img-fluid ms-3" alt="Logo"/>
      </router-link>
      
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>


      <div class="collapse navbar-collapse responsive-header-menu flex-grow-1" id="navbarNav">
        <ul class="navbar-nav">

          <!-- Map -->
          <li class="nav-item">
            <router-link class="btn-menu btn me-1" :class="{ 'active-btn': this.mapBtn }" to="/"><font-awesome-icon :icon="['fas', 'location-dot']" class="mx-2" />Carte</router-link>
          </li>

          <!-- Vehicles -->
          <li class="nav-item">
            <router-link class="btn-menu btn me-1" :class="{ 'active-btn': this.vehicleBtn }" to="/baliseslist"><font-awesome-icon :icon="['fas', 'car']" class="mx-2" />Véhicules</router-link>
          </li>


          <!--
          <li class="nav-item dropdown">
            <a href="#" class="btn-menu btn dropdown-toggle me-1" role="button" data-bs-toggle="dropdown" aria-expanded="false">Dashboard</a>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="">Stats d'utilisation</router-link></li>
              <li><router-link class="dropdown-item" to="">Rapports</router-link></li>
            </ul>            
          </li>
          -->

          <!-- Administration -->
          <li v-if="!this.isViewer && !this.isOperator && !this.isClientUser" class="nav-item dropdown">
            <a href="#" class="btn-menu btn dropdown-toggle me-1" role="button" data-bs-toggle="dropdown" aria-expanded="false" :class="{ 'active-btn': this.adminBtn }">
              <font-awesome-icon :icon="['fas', 'user-gear']" class="mx-2" />
              Administration
            </a>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/users">Gestion des utilisateurs</router-link></li>
              <li><router-link class="dropdown-item" to="/groups">Gestion des groupes</router-link></li>
              <li><router-link class="dropdown-item" to="/patchnotes">Mises à jour</router-link></li>
            </ul>
          </li>

          <!-- Monitoring for super admins -->
          <li v-if="this.isAdmin" class="nav-item dropdown">
            <a href="#" class="btn-menu btn btn-primary dropdown-toggle me-1" role="button" data-bs-toggle="dropdown" aria-expanded="false">Super admin</a>
            <ul class="dropdown-menu">
              <!--
              <li>
                <router-link class="dropdown-item" to="/adminglobal">Alertes</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/serverstatus">Serveurs</router-link>
              </li>
              -->
              <li>
                <router-link class="dropdown-item" to="/balisecmd">Commandes</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/baliseslistall">Véhicules</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/baliseconfig">Config balises</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/billing">Facturation</router-link>
              </li>
            </ul>  
          </li>

          <!-- Client selection for superadmin & superoperators -->
          <li v-if="this.isAdmin || this.isOperator" class="nav-item dropdown">
            <a href="#" class="btn-menu btn dropdown-toggle me-1" role="button" data-bs-toggle="dropdown" aria-expanded="false">Client : {{ watchedClientName }}</a>
            <ul class="dropdown-menu">
              <li
                v-for="client in this.clients"
                :key="client.id">
                <router-link v-if="this.watchedClientId == client.id" class="dropdown-item active-client text-white" to="#" @click="setClientIdToWatch(client.id,client.name)">{{ client.name }}</router-link>
                <router-link v-else class="dropdown-item" to="#" @click="setClientIdToWatch(client.id,client.name)">{{ client.name }}</router-link>
              </li>
            </ul>
          </li>

          <!-- Notifications -->
          <li v-if="!this.isViewer" class="nav-item dropdown d-none d-lg-block">
            <a href="#" class="btn-menu btn me-1 notification-icon" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <font-awesome-icon :icon="['fas', 'bell']" />
              <span v-if="this.lastSeenPatchnote < this.currentPatchnote" class="badge rounded-pill">1</span>
            </a>
            <ul class="dropdown-menu">
              <li v-if="this.lastSeenPatchnote < this.currentPatchnote">
                <router-link class="dropdown-item" to="/patchnotes">Nouvelle version</router-link>
              </li>
              <li v-else>
                <router-link class="dropdown-item disabled" to="/">Aucune notification</router-link>
              </li>
            </ul>
          </li>
          <!-- responsive notification -->
          <li v-if="!this.isViewer" class="nav-item dropdown d-lg-none">
            <a href="#" class="btn-menu btn me-1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <font-awesome-icon :icon="['fas', 'bell']" class="mx-2" />
              <span v-if="this.lastSeenPatchnote < this.currentPatchnote" class="badge rounded-pill notification-badge">1</span>
            </a>
            <ul class="dropdown-menu">
              <li v-if="this.lastSeenPatchnote < this.currentPatchnote">
                <router-link class="dropdown-item" to="/patchnotes">Nouvelle version</router-link>
              </li>
              <li v-else>
                <router-link class="dropdown-item disabled" to="/">Aucune notification</router-link>
              </li>
            </ul>
          </li>
          <!-- Logout -->
          <li class="nav-item">
            <button class="btn-menu-dark btn-logout btn ms-5 me-4" @click.prevent="UserLogout">
              <font-awesome-icon :icon="['fas', 'fa-right-from-bracket']" class="mx-2" />
              Déconnexion
            </button>
          </li>
          
          <!-- Add other navigation items here with the appropriate Bootstrap classes -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ClientService from '../services/client.service.js'
import UserHelper from '../helpers/user.helper.js'

export default {
  props: ['noMargin'],
  data() {
    return {
      watchedClientId: null,
      navBarOpened: false,
      clientList: null,
      watchedClientName: "Clients",
      lastSeenPatchnote: 0,
      routesOnClientChange: {
        "DriveMapPage": "/",
        "BaliseInfo": "/baliseslist",
        "PositionsPage": "/",
        "DrivesPage": "/",
        "DrivePositionsPage": "/",
        "AlertsPage": "/",
        "MapFollowBalise": "/"
      },
      currentPatchnote: 1.16
    };
  },
  computed: {
    clients() {
      if(this.clientList === null)
        return JSON.parse(localStorage.getItem('clients'));

      return this.clientList;
    },
    isAdmin() {
      return UserHelper.isAdmin(this.$store.state.auth.user);
    },
    isViewer() {
      return UserHelper.isViewer(this.$store.state.auth.user);
    },
    isClientUser() {
      return UserHelper.isClientUser(this.$store.state.auth.user);
    },
    isOperator() {
      return UserHelper.isOperator(this.$store.state.auth.user);
    },
    storeWatchedClientId() {
      return JSON.parse(localStorage.getItem('clientIdToWatch'));
    },
    storeWatchedClientName() {
      return JSON.parse(localStorage.getItem('clientNameToWatch'));
    },
    storeLastSeenPatchnote() {
      return JSON.parse(localStorage.getItem('lastSeenPatchnote'));
    },
    // Properties to know what button is selected for the current page :
    mapBtn() {
      if(this.$route.name == "MapPage") {
        return true
      } else {
        return false
      }
    },
    vehicleBtn() {
      if(this.$route.name == "BalisesList"
        || this.$route.name == "BaliseInfo"
        || this.$route.name == "PositionsPage"
        || this.$route.name == "DrivesPage"
        || this.$route.name == "AlertsPage"
        || this.$route.name == "DrivePositionsPage"
      ) {
        return true
      } else {
        return false
      }
    },
    adminBtn() {
      if(this.$route.name == "UsersPage" || this.$route.name == "GroupsPage" || this.$route.name == "PatchNotes") {
        return true
      } else {
        return false
      }
    }
    // End properties define
  },
  mounted() {
    if ((this.isAdmin || this.isOperator) && !this.clients) {
      ClientService.getClients().then(response => {
        localStorage.setItem('clients', JSON.stringify(response.data));
        this.clientList = JSON.parse(localStorage.getItem('clients'));
        localStorage.setItem('clientIdToWatch', JSON.stringify(this.clientList[0].id));
        localStorage.setItem('clientNameToWatch', JSON.stringify(this.clientList[0].name));
        this.watchedClientId = this.clientList[0].id;
        this.watchedClientName = this.clientList[0].name;
        location.reload();
      });
    }
    if(this.storeWatchedClientId) {
      this.watchedClientId = this.storeWatchedClientId;
      this.watchedClientName = this.storeWatchedClientName;
    }
  },
  beforeUpdate() {
    this.lastSeenPatchnote = this.storeLastSeenPatchnote;
  },
  methods: {
    UserLogout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('/');
      },
      (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    setClientIdToWatch(clientId,clientName) {
      localStorage.setItem('clientIdToWatch', JSON.stringify(clientId));
      localStorage.setItem('clientNameToWatch', JSON.stringify(clientName));
      if(this.routesOnClientChange[this.$route.name]) 
      {
        this.$router.push(this.routesOnClientChange[this.$route.name]);
      } 
      else
      {
        location.reload();
      }
    }
  }
}

</script>

<style>

/* Import font */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap');

/* General header CSS */
html {
  font-size: 14px;
}

.header-z {
  z-index: 2000;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
  .responsive-header-menu {
    justify-content: end;
  }
}

@media (max-width: 768px) {
  #navbarNav {
    background-color: #fff;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    height: 100vh;
  }
  .navbar-toggler,
  .navbar-brand {
    z-index: 1;
  }
  .btn-menu, .btn-menu-dark {
    height: 100px;
    width: 100%;
    border-radius: 0%;
    border-bottom: #e5e5e5 1px solid; 
    text-align: left; 
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem; 
    font-weight: bold;
    margin: 0;  
    display: flex;
    align-items: center;
  }
  .dropdown-menu {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem; 
    font-weight: bold;
  }
  .dropdown-menu li {
    height: 80px;
    display: flex;
    align-items: center;
  }
  .btn-logout {
    background-color: #333;
    color: #fff;
    text-align: center;
    margin-left: 0px !important;
    justify-content: center;
    align-items: center;
  }
  .responsive-header-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .responsive-header-menu>ul{
    width: 100%;
  }
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

/* Avatar and dropdown menu CSS */
.nav img {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin: -8px 0;
  float: left;
  margin-right: 10px;
}

.navbar ul li i {
  font-size: 18px;
}

.navbar .dropdown-menu i {
  font-size: 16px;
  min-width: 22px;
}

.navbar .dropdown.open>a {
  background: none !important;
}

.navbar .dropdown-menu {
  border-radius: 1px;
  border-color: #e5e5e5;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .05);
}

.navbar .dropdown-menu li a {
  color: #777;
  padding: 8px 20px;
  line-height: normal;
}

.navbar .dropdown-menu li a:hover,
.navbar .dropdown-menu li a:active {
  color: #333;
}

.navbar .dropdown-menu .material-icons {
  font-size: 21px;
  line-height: 16px;
  vertical-align: middle;
  margin-top: -2px;
}

.navbar .active a,
.navbar .active a:hover,
.navbar .active a:focus {
  background: transparent !important;
}

@media (min-width: 1200px) {
  .form-inline .input-group {
    width: 300px;
    margin-left: 30px;
  }
}

@media (max-width: 1199px) {
  .form-inline {
    display: block;
    margin-bottom: 10px;
  }

  .input-group {
    width: 100%;
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.btn-menu {
  color: #53595f;
}

.btn-menu-dark {
  background-color: rgb(108 117 125);
  color: white;
}

.btn-menu:hover, .btn-menu-dark:hover {
  background-color: #ff641e;
  color: white;
}

.active-client {
  background-color: #ff641e;
}

.active-client:hover {
  background-color: #ff641e;
}

.active-btn {
  border-bottom: 4px solid #ff864f;
}

.notification-icon {
  position: relative;
  font-size: 1.2em;
}

.notification-icon span {
  position: absolute !important;
  top: 4px !important;
  right: 2px;
  font-size: 0.4em;
  background-color: #ff641e;
}

.notification-badge {
  background-color: #ff641e;
}
</style>


