<template>
    <span v-if="gsm == null || gsm == 'undefined'">
        N.C
    </span>
    <span v-else-if="!Number.isInteger(gsm)">
        {{ gsm }}
    </span>
    <span v-else-if="gsm >= 60" class="badge text-nowrap bg-success fw-bold text-white align-baseline">
        <font-awesome-icon :icon="['fas', 'signal']" /> {{ 5*gsm/100 }} / 5
    </span>
    <span v-else-if="gsm >= 20" class="badge text-nowrap bg-warning text-dark fw-bold align-baseline">
        <font-awesome-icon :icon="['fas', 'signal']" /> {{ 5*gsm/100 }} / 5
    </span>
    <span v-else class="badge text-nowrap bg-danger fw-bold text-white align-baseline">
        <font-awesome-icon :icon="['fas', 'signal']" /> {{ 5*gsm/100 }} / 5
    </span>
</template>

<script>
export default {
  props: ['gsm']
}
</script>