<template>
    <div class="btn-group">
        <button class="btn bg-white border dropdown-toggle" href="#" id="dropdownMenuButton1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <span v-if="selectedBalise">
                <img :src="require(`../../assets/icons/` + this.selectedBalise.icon)"
                :style="{ 'height': '32px' }" 
                class="sm me-2">
                <small class="text-muted">{{ this.selectedBalise.name }}</small>
            </span>
            <small v-else class="text-muted">Véhicules...</small>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li v-for="balise in this.balises"
            :key="balise.id">
                <a v-on:click="$emit('baliseChange', balise)"
                href="#"
                class="dropdown-item">
                    <img :src="require(`../../assets/icons/` + balise.icon)"
                    :style="{ 'height': '32px' }" 
                    class="sm me-2">
                    <small class="text-muted">{{ balise.name }}</small>
                </a>
            </li>
        </ul>
    </div>
</template>


<script>
import BaliseService from '../../services/balise.service.js'
import FormatHelper from '../../helpers/format.helper.js'

export default {
    props: ['selectedBaliseId'],
    watch: { 
        selectedBaliseId: function() {
            this.setSelectedBalise(this.selectedBaliseId);
        }
    },
    emits: ['baliseChange'],
    data() {
        return {
            balises: null,
            selectedBalise: null
        }
    },
    mounted() {
        this.getBalises();
    },
    methods: {
        getBalises() {
            BaliseService.getGroupBalisesLastPositions()
                .then(response => {
                    this.balises = FormatHelper.balisesResponseFormat(response.data);
                    this.setSelectedBalise(this.selectedBaliseId);
                    this.$emit('baliseChange', this.selectedBalise);
                })
                .catch(e => {
                    console.log(e);
                });
        },
        setSelectedBalise(bId) {
            if(this.balises != null && bId != null) {
                this.balises.forEach(balise => {
                    if(balise.id == bId) {
                        this.selectedBalise = balise;
                    }
                });
            }
        }
    }
}
</script>
