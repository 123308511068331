<template>
    <DefaultHeader />
    <div class="container offres">
        <h1 class="text-center">Offres sécurité</h1>
        <p>Ces offres sont valables pour les balises de type NS. L'intégralité du parc sera facturé au montant choisi.</p>
        <table>

            <colgroup></colgroup>
            <colgroup></colgroup>
            <colgroup></colgroup>
            <colgroup></colgroup>

            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>
                        <h2>Starter</h2>
                        <p>9.99€</p>
                    </th>
                    <th>
                        <h2>Pro</h2>
                        <p>15.99€</p>
                        <span>Offre Recommandée</span>
                    </th>
                    <th>
                        <h2>Entreprise</h2>
                        <p>25.99€</p>
                    </th>
                </tr>
            </thead>

            <tfoot>
                <tr>
                    <th>&nbsp;</th>
                    <td><button class="btn btn-secondary btn-lg" disabled>Offre actuelle</button></td>
                    <td><button class="btn btn-primary btn-lg">Nous contacter</button></td>
                    <td><button class="btn btn-primary btn-lg">Nous contacter</button></td>
                </tr>
            </tfoot>

            <tbody>
                <tr>
                    <th>Nombre d'accès<span>Nombre de compte ayant accès au back office de surveillance.</span></th>
                    <td>2</td>
                    <td>10</td>
                    <td>Sans limite</td>
                </tr>
                <tr>
                    <th>Durée de stockage<span>Durée pendant laquelle nous gardons l'historique de vos balises et l'enregistrement de leurs activités.</span></th>
                    <td>6 mois</td>
                    <td>1 année</td>
                    <td>2 années</td>
                </tr>
                <tr>
                    <th>Support prioritaire<span>Nous prenons immédiatement vos problèmes en charge et les mettons en priorité.</span></th>
                    <td>&mdash;</td>
                    <td>Oui</td>
                    <td>Oui</td>
                </tr>
                <tr>
                    <th>Nom de domaine personnalisé<span>Possibilité d'avoir un nom de domaine personnalisé pour l'hébergement du service.</span></th>
                    <td>&mdash;</td>
                    <td>&mdash;</td>
                    <td>Oui</td>
                </tr>
                <tr>
                    <th>Icones personnalisés<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.</span></th>
                    <td>Lorem ipsum dolor sit amet</td>
                    <td>&mdash;</td>
                    <td>&mdash;</td>
                </tr>
                <tr>
                    <th>Feature "Lorem ipsum" <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.</span></th>
                    <td>Lorem ipsum dolor sit amet</td>
                    <td>&mdash;</td>
                    <td>&mdash;</td>
                </tr>
            </tbody>

        </table>
    </div>
    <DefaultFooter />
</template>
    
<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'

export default {
    components: {
        DefaultHeader,
        DefaultFooter
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    }
}
</script>

<style>
/*body { background: #fff; font: 400 1em/1.4 "Open Sans", "Helvetica Neue", sans-serif; color: #333; text-align: center; padding: 4em 2em; }*/

.offres h1 { font-weight: 300; font-size: 5em; line-height: 1.35; margin: 0 0 .125em; }
.offres h1 + p { font-size: 1.5em; color: #999; max-width: 30em; margin: 0 auto 3em; }

.offres table { width: 100%; text-align: left; border-spacing: 0; border-collapse: collapse; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.offres th, td { font-family: inherit; font-size: .875em; line-height: 1.45; color: #444; vertical-align: middle; padding: 1em; }
.offres th { font-weight: 600; }

.offres colgroup:nth-child(1) { width: 31%; border: 0 none; }
.offres colgroup:nth-child(2) { width: 22%; border: 1px solid #ccc; }
.offres colgroup:nth-child(3) { width: 25%; border: 10px solid #59c7fb; }
.offres colgroup:nth-child(4) { width: 22%; border: 1px solid #ccc; }

/* Tablehead */

.offres thead th { background: #def4fe; background: -moz-linear-gradient(top,  #ffffff 0%, #f5f5f5 100%); background: -webkit-linear-gradient(top,  #ffffff 0%,#f5f5f5 100%); background: -o-linear-gradient(top,  #ffffff 0%,#f5f5f5 100%); background: -ms-linear-gradient(top,  #ffffff 0%,#f5f5f5 100%); background: linear-gradient(to bottom,  #ffffff 0%,#f5f5f5 100%); text-align: center; position: relative; border-bottom: 1px solid #ccc; padding: 3em 0 2em; font-weight: 400; color: #999; }
.offres thead th:nth-child(1) { background: transparent;  }
.offres thead th:nth-child(3) {  padding: 2em 0 5em; }
.offres thead th h2 { font-weight: 300; font-size: 2.4em; line-height: 1.2; color: #59c7fb; }
.offres thead th h2 + p { font-size: 1.25em; line-height: 1.4; }
.offres thead th:nth-child(3) h2 { font-size: 3.6em; }
.offres thead th:nth-child(3) h2 + p { font-size: 1.5em; }

/* Tablebody */

.offres tbody th { background: #fff; border-left: 1px solid #ccc; }
.offres tbody th span { font-weight: normal; font-size: 87.5%; color: #999; display: block; }

.offres tbody td { background: #fff; text-align: center; }

.offres tbody tr:nth-child(even) th,
.offres tbody tr:nth-child(even) td { background: #f5f5f5; border: 1px solid #ccc; border-width: 1px 0 1px 1px; }
.offres tbody tr:last-child td { border-bottom: 0 none; }

/* Tablefooter */

.offres tfoot th  { padding: 2em 1em; border-top: 1px solid #ccc; }
.offres tfoot td  { text-align: center; padding: 2em 1em; border-top: 1px solid #ccc; }

.offres tfoot button {
    font-weight: bold; 
    text-transform: uppercase;
}
</style>