import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL+'position/';

class PositionService {

  getBalisePositions(baliseId, offset, limit, startDate = null, endDate = null, order = "DESC") {

    var UrlToCall;
    UrlToCall = API_URL + 'getbalisepositions/'+ baliseId + '?offset=' + offset + '&limit=' + limit + '&order=' + order;
    UrlToCall = (startDate != null) ? (UrlToCall + '&startDate=' + startDate) : UrlToCall;
    UrlToCall = (endDate != null) ? (UrlToCall + '&endDate=' + endDate) : UrlToCall;
    
    return axios.get(UrlToCall, { headers: authHeader() });
  }

}

export default new PositionService();