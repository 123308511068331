import axios from 'axios';
import authHeader from './auth-header';
import UserHelper from '../helpers/user.helper.js'

const API_URL = process.env.VUE_APP_API_URL+'user/';

class UserService {
  getClientUsers() {
    return axios.get(API_URL + 'getclientusers/' + UserHelper.getClientId(), { headers: authHeader() });
  }

  deleteUser(userId) {
    return axios.delete(API_URL + 'deleteuser/' + userId, { headers: authHeader() });
  }

  updateRole(userId, role) {
    return axios.put(API_URL + 'updateuserrole/' + userId,
    { role: role }, 
    { headers: authHeader() });
  }

  updateGroups(userId, groups) {
    return axios.put(API_URL + 'updateallowedgroups/' + userId,
    { allowedGroups: groups }, 
    { headers: authHeader() });
  }
}

export default new UserService();