<template>
    <div class="btn-group">
        <a v-if="selectedDrive != null" class="btn bg-white border dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <small class="text-muted">{{ selectedDrive.displayStartDate }}</small>
        </a>
        <a v-else class="btn bg-white border dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <small class="text-muted">Trajets...</small>
        </a>
        <div class="dropdown-menu">
            <router-link v-for="drive in this.drives"
            :key="drive.id"
            :to="'/drivemap/' + drive.id"
            class="dropdown-item"
            href="#">
                <small class="text-muted">{{ drive.displayStartDate }}</small>
            </router-link>
        </div>
    </div>
</template>

<script>
import DrivesService from '../../services/drive.service'
import FormatHelper from '../../helpers/format.helper.js'


export default {
    props: ['baliseId', 'selectedDrive'],
    watch: {
        baliseId: function() {
            this.getDrives();
        }
    },
    data() {
        return {
            drives: []
        }
    },
    mount() {
        this.getDrives();
    },
    methods: {
        getDrives() {
            this.drives = [];
            if(this.baliseId != 'undefined') {
                DrivesService.getBaliseDrives(this.baliseId, 0, 10)
                .then(response => {
                    this.drives = FormatHelper.baliseDrivesFormat(response.data.rows)
                })
            }
        }
    }
}
</script>