<template>
    <DefaultHeader />
    <div class="container d-flex flex-column">
        <div class="row mb-2">

            <!-- Balise alert table -->
            <div class="col-md-6">
                <div class="overflow-hidden card table-nowrap table-card">
                    <div class="table-responsive">
                        <table class="table table-striped mb-0">
                            <thead class="small text-uppercase bg-body text-muted">
                                <tr>
                                    <th>Véhicule</th>
                                    <th>Alerte</th>
                                </tr>
                            </thead>
                            <tbody>
                                <BaliseAlertTableRow 
                                    v-for="balise in balises"
                                    :key="balise.id"
                                    :balise="balise"
                                    :groups="groups"
                                    :icons="icons"
                                    @param="setBaliseToUpdate" />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- Server status alert -->
            <div class="col-md-6 d-flex flex-column">
                <div class="bg-light card p-3 rounded">
                    <div class="text-center fw-bold text-uppercase mb-4">Etat des services de surveillance</div>
                    <div class="text-center mb-4">
                        <img class="me-2" :src="require(`../assets/green.png`)"> Tous les services
                    </div>
                    <div class="text-center mb-4">
                        <RouterLink to="/serverstatus">Voir le détail</RouterLink>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <IconPickerModal :icons="icons" :baliseToUpdate="baliseToUpdate"/>
    <DefaultFooter />
</template>
    
<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'
import IconPickerModal from '../components/balisesPage/IconPickerModal.vue'
import BaliseService from '../services/balise.service.js'
import GroupService from '../services/group.service.js'
import BaliseAlertTableRow from '../components/adminGlobalPage/BaliseAlertTableRow.vue'
import FormatHelper from '../helpers/format.helper.js'


export default {
    components: {
        DefaultHeader,
        DefaultFooter,
        BaliseAlertTableRow,
        IconPickerModal
    },
    data() {
        return {
            balises: null,
            groups: {},
            icons : require.context('../assets/icons/', false, /^.*\.png$/),
            baliseToUpdate : null
        };
    },
    mounted() {
        this.refreshData();
        this.getGroups();
    },
    methods: {
        refreshData() {
            BaliseService.getGroupBalisesLastPositions()
                .then(response => {
                    this.balises = FormatHelper.balisesResponseFormat(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        },
        getGroups() {
            GroupService.getClientGroups()
                .then(response => {
                    this.groups = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        setBaliseToUpdate(param) {
            this.baliseToUpdate = param;
        }
    }
}
</script>