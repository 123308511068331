<template>
    <span v-if="!Number.isInteger(temperature)">
        {{ temperature }}
    </span>
    <span v-else-if="temperature <= 5" class="badge text-nowrap bg-info fw-bold text-white align-bottom">
        <font-awesome-icon :icon="['fas', 'temperature-empty']" size="lg" /> {{ temperature }} °
    </span>
    <span v-else-if="temperature <= 40" class="badge text-nowrap bg-success fw-bold text-white align-bottom">
        <font-awesome-icon :icon="['fas', 'temperature-half']" size="lg" /> {{ temperature }} °
    </span>
    <span v-else-if="temperature >= 41" class="badge text-nowrap bg-warning text-dark fw-bold align-bottom">
        <font-awesome-icon :icon="['fas', 'temperature-full']" size="lg" /> {{ temperature }} °
    </span>
</template>

<script>
export default {
  props: ['temperature']
}
</script>