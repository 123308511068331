<template>
    <div class="row d-flex justify-content-center border-bottom">
        <div class="col-3">
            {{ billing.name }}
        </div>
        <div class="col-3">
            {{ billing.packageAmount }} (unité {{ billing.packagePrice }})
        </div>
        <div class="col-3">
            {{ billing.remoteMonitAmount }} (unité {{ billing.remoteMonitPrice }})
        </div>
        <div class="col-3">
            {{ ((billing.packageAmount*billing.packagePrice).toFixed(2) * 100 + (billing.remoteMonitAmount*billing.remoteMonitPrice).toFixed(2) * 100) / 100 }}
        </div>
    </div>
</template>
        
<script>
export default {
    props: ['billing']
}
</script>