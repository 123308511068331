<template>
    <l-marker v-bind:lat-lng="[marker.latitude, marker.longitude]">
      <l-tooltip>
        <ul class="list-unstyled list-inline d-flex justify-content-between">
          <li class="list-inline-item">
            <span class="text-nowrap">{{ marker.date }}</span>
          </li>
          <li class="list-inline-item">
            <BatteryDisplay :battery="marker.battery" />
          </li>
        </ul>
        <ul class="list-unstyled list-inline d-flex justify-content-between">
          <li class="list-inline-item">
            {{ marker.speed }} km/h
          </li>
          <li class="list-inline-item">
            GPS : <GPSDisplay :gps="marker.levelGPS"></GPSDisplay>
          </li>
          <li class="list-inline-item">
            GSM : <GSMDisplay :gsm="marker.levelGSM"></GSMDisplay>
          </li>
        </ul>
        <div class="fw-bold">Adresse :</div>
        <div>{{ marker.address }}</div>
        <div v-if="marker.alert != null">
            <AlertDisplay :alert="marker.alert" />
        </div>
      </l-tooltip>
      <l-icon v-if="marker.alert != null" class-name="someExtraClass" style="z-index: 1;"
        :icon-size=[32,32]
        :icon-anchor=[16,48]>
        <AlertMapDisplay :alert="marker.alert" />
      </l-icon>
      <l-icon v-else class-name="someExtraClass"
        :icon-size=this.iconSize
        :icon-anchor=this.iconAnchor>
        <PositionDirection :position="marker" />
      </l-icon>
    </l-marker>
  </template>
    
  <script>
  import AlertDisplay from '../display/AlertDisplay.vue'
  import AlertMapDisplay from '../display/AlertMapDisplay.vue'
  import BatteryDisplay from '../display/BatteryDisplay.vue'
  import GPSDisplay from '../display/GPSDisplay.vue'
  import GSMDisplay from '../display/GSMDisplay.vue'
  import PositionDirection from "../display/PositionDirection.vue";
  import { LMarker, LTooltip, LIcon } from "@vue-leaflet/vue-leaflet";

  export default {
    props: ['marker', 'iconSize', 'iconAnchor'],
    components: {
      LMarker,
      LTooltip,
      LIcon,
      BatteryDisplay,
      PositionDirection,
      GPSDisplay,
      GSMDisplay,
      AlertDisplay,
      AlertMapDisplay
    }
  }
  </script>