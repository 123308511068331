import axios from 'axios';
import authHeader from './auth-header';
import UserHelper from '../helpers/user.helper.js'

const API_URL = process.env.VUE_APP_API_URL+'phonenumber/';

class PhoneNumberService {

  getClientNumbers() {
    return axios.get(API_URL + 'getclientnumbers/' + UserHelper.getClientId(), { headers: authHeader() });
  }

  getNumberInfo(numberId) {
    return axios.get(API_URL + 'get/' + numberId, { headers: authHeader() });
  }

  addClientNumbers(name, number) {
    return axios.put(API_URL + 'create/' + UserHelper.getClientId(), 
    {
      name: name,
      number: number
    },
    { headers: authHeader() });
  }

  deleteNumber(numberId) {
    return axios.delete(API_URL + 'delete/' + numberId, { headers: authHeader() });
  }

  updateNumber(numberId, newData) {
    return axios.put(API_URL + 'update/' + numberId, newData, { headers: authHeader() });
  }

}

export default new PhoneNumberService();