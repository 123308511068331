import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL+'client/';

class ClientService {
    getClients() {
        return axios.get(API_URL + 'getclientlist', { headers: authHeader() });
    }
}

export default new ClientService();