<template>
<DefaultHeader />
    <div class="container-fluid">
        <div class="row d-flex justify-content-center">
            <div class="col-11 mb-3 mb-lg-5">
                <div class="card table-nowrap table-card">
                    <div class="table-responsive">
                        <table class="table table-striped mb-0">
                            <thead class="small text-uppercase bg-body text-muted">
                                <tr class="align-middle">
                                    <td>
                                        <div class="form-group">
                                            <input v-model="name" type="text" class="form-control" placeholder="Nouveau groupe">
                                        </div>
                                    </td>
                                    <td>
                                        <span v-if="this.errorMessage" class="text-danger">Nom de groupe invalide</span>
                                    </td>
                                    <td class="text-end">
                                        <button class="btn btn-primary btn-block" @click="createGroup()"><font-awesome-icon :icon="['fas', 'circle-plus']" /> Ajouter le groupe</button>
                                    </td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Groupe</th>
                                    <th>Visibilité</th>
                                    <th class="text-center">Nombre de véhicules</th>
                                    <th>Date de création</th>
                                    <th class="text-end"></th>
                                </tr>
                                <TableRow 
                                    v-for="group in groups"
                                    :key="group.id"
                                    :group="group" />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
<DefaultFooter />
</template>
    
<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'
import TableRow from '../components/groupsPage/TableRow.vue'
import GroupService from '../services/group.service.js'

export default {
    components: {
        DefaultHeader,
        DefaultFooter,
        TableRow
    },
    data() {
        return {
            groups: null,
            errorMessage : false
        };
    },
    mounted() {
        this.refreshData();
    },
    methods: {
        refreshData() {
            GroupService.getClientGroups()
            .then(response => {
                this.groups = response.data;
            })
            .catch(e => {
                console.log(e);
            });
        },
        createGroup() {
            if (!this.name) {
                this.errorMessage = true;
            } else {
                GroupService.createGroup(this.name)
                .then(() => {
                    this.refreshData();
                    this.name = "";
                    this.errorMessage = false;
                })
                .catch(e => {
                    console.log(e);
                });
            }
        }
    }
}
</script>