<template>
<tr :class="{ 'd-none' : this.hideDefault }">
    <td>
        <div class="d-flex align-items-center">
            <font-awesome-icon :icon="['fas', 'user']" class="me-1"/>
            <div>
                <div class="h6 mb-0 lh-1">{{ this.user.email }}</div>
            </div>
        </div>
    </td>
    <td v-if="this.user.lastConnection">{{ $filters.secondDateFormat(this.user.lastConnection) }}<br>{{ $filters.timeAgo(this.user.lastConnection) }}</td>
    <td v-else>Jamais</td>
    <td v-if="this.user.role.name == 'clientadmin'">Administrateur</td>
    <td v-else-if="this.user.role.name == 'clientoperator'">Utilisateur</td>
    <td v-else-if="this.user.role.name == 'clientviewer'">Visiteur</td>
    <td>
        <div v-for="group in user.groups"
        :key="group.id" class="badge bg-secondary text-white text-decoration-none small text-wrap mx-1">
            {{ group.name }}
        </div>
    </td>

    <td class="text-end">
        <div>
            <a data-bs-toggle="dropdown" href="#" class="btn p-1" aria-expanded="false">
                <font-awesome-icon :icon="['fas', 'bars']"/>
            </a>
            <div class="dropdown-menu dropdown-menu-end" style="">
                <a href="#!" v-on:click="this.hideDefault=true;this.hideRole=false" class="dropdown-item">Modifier les droits</a>
                <a href="#!" v-on:click="this.hideDefault=true;this.hideGroups=false" class="dropdown-item">Modifier les groupes</a>
                <a href="#!" v-on:click="this.hideDefault=true;this.hidePassword=false" class="dropdown-item">Changer le mot de passe</a>
                <a href="#!" v-on:click="deleteUser(this.user.id)" class="dropdown-item">Supprimer l'utilisateur</a>
            </div>
        </div>
    </td>
</tr>
<tr :class="{ 'd-none' : this.hideRole }">
    <td>
        <div class="d-flex align-items-center">
            <font-awesome-icon :icon="['fas', 'user']" class="me-1"/>
            <div>
                <div class="h6 mb-0 lh-1">{{ this.user.email }}</div>
            </div>
        </div>
    </td>
    <td class="text-center" colspan="4">
        <form @submit.prevent class="row g-3">
            <div class="col-auto">
                <select v-model="role" class="form-select">
                    <option selected value="clientviewer">Visiteur</option>
                    <option value="clientoperator">Opérateur</option>
                    <option value="clientadmin">Administrateur</option>
                </select>
            </div>
            <div class="col-auto">
                <button class="btn btn-primary btn-block" v-on:click="this.hideRole=true;this.hideDefault=false;updateRole(this.user.id)">Valider</button>
            </div>
            <div class="col-auto">
                <button class="btn btn-danger btn-block" v-on:click="this.hideRole=true;this.hideDefault=false">Annuler</button>
            </div>
        </form>
    </td>
</tr>
<tr :class="{ 'd-none' : this.hidePassword }">
    <td>
        <div class="d-flex align-items-center">
            <font-awesome-icon :icon="['fas', 'user']" class="me-1"/>
            <div>
                <div class="h6 mb-0 lh-1">{{ this.user.email }}</div>
            </div>
        </div>
    </td>
    <td class="text-center" colspan="4">
        <form @submit.prevent class="row g-3">
            <div class="col-auto">
                <input v-model="password" type="password" class="form-control" placeholder="Nouveau mot de passe">
            </div>
            <div class="col-auto">
                <button class="btn btn-primary btn-block" v-on:click="this.hidePassword=true;this.hideDefault=false;updatePassword(this.user.id)">Valider</button>
            </div>
            <div class="col-auto">
                <button class="btn btn-danger btn-block" v-on:click="this.hidePassword=true;this.hideDefault=false">Annuler</button>
            </div>
        </form>
    </td>
</tr>
<tr :class="{ 'd-none' : this.hideGroups }">
    <td>
        <div class="d-flex align-items-center">
            <font-awesome-icon :icon="['fas', 'user']" class="me-1"/>
            <div>
                <div class="h6 mb-0 lh-1">{{ this.user.email }}</div>
            </div>
        </div>
    </td>
    <td class="text-center" colspan="4">
        <div class="row g-3">
            <div v-for="group in this.groups" :key="group.id" class="form-check col-auto">
                <input type="checkbox" v-model="groupSelected" :value="group.id" class="form-check-input">
                <label class="form-check-label">
                    {{ group.name }}
                </label>
            </div>
            <div class="col-auto">
                <button class="btn btn-primary btn-block" v-on:click="this.hideGroups=true;this.hideDefault=false;updateGroups()">Valider</button>
            </div>
            <div class="col-auto">
                <button class="btn btn-danger btn-block" v-on:click="this.hideGroups=true;this.hideDefault=false">Annuler</button>
            </div>
        </div>
    </td>
</tr>
</template>
    
<script>
import UserService from '../../services/user.service.js'
import AuthService from '../../services/auth.service.js'

export default {
    props: ['user', 'groups'],
    data() {
        return {
            hideDefault: false,
            hideRole: true,
            hidePassword: true,
            hideGroups: true,
            groupSelected: []
        }
    },
    mounted() {
        this.user.groups.forEach(userGroup => {
            this.groupSelected.push(userGroup.id);
        });
    },
    methods: {
        deleteUser(uId) {
            if (confirm("Supprimer l'utilisateur ?")) {
                UserService.deleteUser(uId)
                .then(() => {
                    this.$parent.refreshData();
                })
                .catch(e => {
                    console.log(e);
                });
            }
        },
        updatePassword(uId) {
            AuthService.updatePassword(uId, this.password)
            .then(() => {
                this.$parent.refreshData();
            })
            .catch(e => {
                console.log(e);
            });
        },
        updateRole(uId) {
            UserService.updateRole(uId, this.role)
            .then(() => {
                this.$parent.refreshData();
            })
            .catch(e => {
                console.log(e);
            });
        },
        updateGroups() {
            UserService.updateGroups(this.user.id, this.groupSelected)
            .then(() => {
                this.$parent.refreshData();
            })
            .catch(e => {
                console.log(e);
            });
        }
    }
}
</script>