<template>
    <div class="row mt-2">
        <div class="col-4">
            <div>Nom</div>
            <input v-model="this.dInInfo.name" type="text" class="form-control">
        </div>
        <div class="col-2">
            <div>Activation</div>
            <div class="form-check form-switch">
                <input v-model="this.dInInfo.activated" class="form-check-input"
                    type="checkbox" role="switch">
            </div>
        </div>
        <!--
        <div class="col-2">
            <div>Valeur actuelle</div>
            {{ this.dInInfo.value }}
        </div>
        <div class="col-2">
            <div>Dernière maj</div>
            <div v-if="this.dInInfo.lastUpdate">{{ this.dInInfo.lastUpdate }}</div>
            <div v-else>Aucune maj</div>
        </div>-->
        <div class="col-4 align-self-end">
            <button v-on:click="$emit('dInChange', this.dInInfo.activated, this.dInInfo.name); this.dataChanged=false" :disabled="this.dataChanged ? false : true" class="btn btn-block btn-primary mx-1">Enregistrer</button>
            <button v-on:click="this.resetData" :disabled="this.dataChanged ? false : true" class="btn btn-block btn-danger mx-1">Annuler</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['dIn'],
        data() {
            return {
                dInInfo: {},
                dataChanged: false
            }
        },
        mounted() {
            this.dInInfo.activated = this.dIn.activated;
            this.dInInfo.name = this.dIn.name;
        },
        watch: {
            'dInInfo.name': function() {
                if(this.dInInfo.name == this.dIn.name && this.dInInfo.activated == this.dIn.activated)
                    this.dataChanged = false;
                else
                    this.dataChanged = true;
            },
            'dInInfo.activated': function() {
                if(this.dInInfo.name == this.dIn.name && this.dInInfo.activated == this.dIn.activated)
                    this.dataChanged = false;
                else
                    this.dataChanged = true;
            },
        },
        methods: {
            resetData() {
                this.dInInfo.name = this.dIn.name;
                this.dInInfo.activated = this.dIn.activated;
            }
        }
    }
</script>