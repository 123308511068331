<template>
    <DefaultHeader />
  <!-- FAQs Start -->
  <div class="container-fluid py-5">
    <div class="container py-5">
      <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style="max-width: 500px;">
        <h1 class="mb-4">Questions fréquentes</h1>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="accordion" id="accordionFAQ1">
            <div v-for="(item, index) in faqItemsFirstCol" :key="index" class="accordion-item wow fadeIn"
              data-wow-delay="0.1s">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  :data-bs-target="`#q0` + index" aria-expanded="false" :aria-controls="`q0` + index">
                  {{ item.question }}
                </button>
              </h2>
              <div :id="`q0` + index" class="accordion-collapse collapse"
                data-bs-parent="#accordionFAQ1">
                <div class="accordion-body">
                  {{ item.answer }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="accordion" id="accordionFAQ2">
            <div v-for="(item, index) in faqItemsSecondCol" :key="index" class="accordion-item wow fadeIn"
              data-wow-delay="0.1s">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  :data-bs-target="`#q1` + index" aria-expanded="false" :aria-controls="`q1` + index">
                  {{ item.question }}
                </button>
              </h2>
              <div :id="`q1` + index" class="accordion-collapse collapse"
                data-bs-parent="#accordionFAQ1">
                <div class="accordion-body">
                  {{ item.answer }}
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FAQs End -->

    <DefaultFooter />
</template>

<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'


export default {
    components: {
        DefaultHeader,
        DefaultFooter
    },
    data() {
        return {
            faqItemsFirstCol: [
                { question: 'Pourquoi ne puis-je pas gérer les utilisateurs et les groupes de véhicules ?', answer: 'Pour gérer ces fonctionnalités il est nécessaire de disposer du rôle utilisateur approrié. Si vous pensez que le rôle qui vous est attribué n\'est pas le bon, veuillez contacter votre adminstrateur d\'entreprise.' },
                { question: 'Puis-je me connecter depuis mon téléphone ?', answer: 'Bien évidemment ! Vous pouvez vous connecter sur argos-geoloc.com depuis le navigateur de votre téléphone. Vous y trouverez l\'intégralité des fonctionnalités présentes sur le site web.' },
                { question: 'J\'aimerais changer d\'offre ou renouveller mes balises, comment puis-je faire ?', answer: 'Pour modifier votre offre ou demander des modifications de balises, veuillez prendre contact avec notre support commercial, par mail à argos@sms-active.com.' },
                { question: 'Qui peut modifier mes véhicules ?', answer: 'Seuls vos utilisateurs avec un niveau d\'accès "Administrateur" ou "Utilisateur" peuvent modifier vos véhicules (libellé, plaque, etc...). Sur demande particulière de votre part, votre administrateur SMS peut effectuer une modification, mais nos opérateurs n\'ont pas accès aux modifications des informations de véhicules.' },
                // Ajoutez d'autres questions-réponses selon vos besoins
            ],
            faqItemsSecondCol: [
                { question: 'Qui êtes-vous ?', answer: 'Les services Argos sont gérés par SMS (Sûreté Management Services) et développés par l\'agence digitale R42. Pour en savoir plus, retrouvez-nous sur notre site web SMS : https://sms-active.fr/' },
                { question: 'Quels services proposez-vous ?', answer: 'Nous offrons des services de géolocalisation et de télésurveillance de véhicules et flottes de véhicules. Pour en savoir plus sur nos offres, n\'hésitez pas à contacter notre support commercial : argos@sms-active.com.' },
                { question: 'Comment se font les mises à jour ?', answer: 'Afin d\'améliorer les services en permanence et de maintenir un haut niveau de sécurité, des mises à jours sont effectuées régulièrement. Si vous êtes administrateur, vous pouvez retrouver la liste des mises à jour dans l\'onglet "Mises à jour" du menu Administration.' },
                { question: 'Je rencontre un problème sur la plateforme, à qui puis-je envoyer l\'information ?', answer: 'Si vous rencontrez un problème technique et que votre administrateur d\'entreprise ne peut pas vous aider, veuillez envoyer un e-mail à notre support : argos@sms-active.com.' },
                // Ajoutez d'autres questions-réponses selon vos besoins
            ],
        };
    },

}
</script>


  <!-- Partie CSS (ajoutée pour styliser l'accordéon) -->
<style scoped>
/*** Heading ***/
h1,
h2,
h3,
.fw-bold {
    font-weight: 700 !important;
}

h4,
h5,
h6,
.fw-medium {
    font-weight: 500 !important;
}

/*** Button ***/
.btn {
    font-weight: 500;
    transition: .5s;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}

/*** FAQs ***/
.accordion .accordion-item {
    border: none;
    margin-bottom: 15px;
}

.accordion .accordion-button {
    background: #F4F7FE;
    border-radius: 2px;
}

.accordion .accordion-button:not(.collapsed) {
    color: #FFFFFF;
    background: #1363C6;
    box-shadow: none;
}

.accordion .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion .accordion-body {
    padding: 15px 0 0 0;
}
</style>
  