import axios from 'axios';

const API_URL = 'https://api-adresse.data.gouv.fr/reverse/?lon=';

class GeocodingService {

  getAddress(latitude, longitude) {
    return axios.get(API_URL + longitude + '&lat=' + latitude);
  }
}

export default new GeocodingService();