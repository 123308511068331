import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL+'alert/';

class AlertService {

  getBaliseAlerts(baliseId, offset, limit, startPage = null, endPage = null) {

    var UrlToCall;
    UrlToCall = API_URL + 'getbalisealerts/'+ baliseId + '?offset=' + offset + '&limit=' + limit;
    UrlToCall = (startPage != null) ? (UrlToCall + '&startDate=' + startPage) : UrlToCall;
    UrlToCall = (endPage != null) ? (UrlToCall + '&endDate=' + endPage) : UrlToCall;
    
    return axios.get(UrlToCall, { headers: authHeader() });
  }

  getClientAlerts(clientId, offset, limit, startPage = null, endPage = null) {

    var UrlToCall;
    UrlToCall = API_URL + 'getclientalerts/'+ clientId + '?offset=' + offset + '&limit=' + limit;
    UrlToCall = (startPage != null) ? (UrlToCall + '&startDate=' + startPage) : UrlToCall;
    UrlToCall = (endPage != null) ? (UrlToCall + '&endDate=' + endPage) : UrlToCall;
    
    return axios.get(UrlToCall, { headers: authHeader() });
  }

}

export default new AlertService();