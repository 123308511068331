import { createApp } from 'vue'
import App from './App.vue'
import * as VueRouter from 'vue-router'
import store from "./store"
import moment from 'moment'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue.js'

import LoginPage from './views/LoginPage.vue'
import MapPage from './views/MapPage.vue'
import DriveMapPage from './views/DriveMapPage.vue'
import OffersPage from './views/OffersPage.vue'
import BaliseInfo from './views/BaliseInfo.vue'
import BaliseConfig from './views/BaliseConfig.vue'
import BalisesList from './views/BalisesList.vue'
import BalisesListAll from './views/BalisesListAll.vue'
import GroupsPage from './views/GroupsPage.vue'
import UsersPage from './views/UsersPage.vue'
import AdminGlobalPage from './views/AdminGlobalPage.vue'
import GeocodingPage from './views/GeocodingPage.vue'
import FAQPage from './views/FAQPage'
import ContactPage from './views/ContactPage'
import ListenersStatus from './views/ListenersStatus'
import PositionsPage from './views/PositionsPage'
import AlertsPage from './views/AlertsPage'
import AlertsSMSPage from './views/AlertsSMSPage'
import DrivesPage from './views/DrivesPage'
import DrivePositionsPage from './views/DrivePositionsPage'
import PatchNotes from './views/PatchNotes'
import ReportPage from './views/ReportPage.vue'
import BaliseCommand from './views/BaliseCommand.vue'
import BillingPage from './views/BillingPage'


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import
    {
        faQuoteLeft,
        faStar,
        faLocationDot,
        faLocationPin,
        faCircle,
        faUsers,
        faUser,
        faBars,
        faBan,
        faCirclePlus,
        faSquareMinus,
        faBatteryQuarter,
        faBatteryFull,
        faCar,
        faBell,
        faUserGear,
        faRightFromBracket,
        faArrowUpRightFromSquare,
        faPenSquare,
        faLocationArrow,
        faPlug,
        faSignal,
        faChevronUp,
        faChevronDown,
        faTriangleExclamation,
        faGlobe,
        faTemperatureHalf,
        faTemperatureFull,
        faTemperatureEmpty,
        faGaugeHigh,
        faFlagCheckered,
        faCirclePlay,
        faSpinner,
        faRotateRight,
        faThumbtack,
        faThumbtackSlash,
        faXmark,
        faPlay,
        faStop,
        faCircleInfo
    } from '@fortawesome/free-solid-svg-icons'
import
    {
        faInstagramSquare,
        faLinkedin,
        faFacebookSquare,
        faTwitterSquare
    } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(
    faQuoteLeft,
    faStar,
    faInstagramSquare,
    faLinkedin,
    faFacebookSquare,
    faTwitterSquare,
    faUsers,
    faUser,
    faBars,
    faBan,
    faCirclePlus,
    faBatteryQuarter,
    faBatteryFull,
    faArrowUpRightFromSquare,
    faPenSquare,
    faLocationDot,
    faLocationPin,
    faCircle,
    faSquareMinus,
    faLocationArrow,
    faPlug,
    faSignal,
    faChevronDown,
    faChevronUp,
    faTriangleExclamation,
    faGlobe,
    faTemperatureHalf,
    faTemperatureFull,
    faTemperatureEmpty,
    faCar,
    faUserGear,
    faRightFromBracket,
    faBell,
    faGaugeHigh,
    faFlagCheckered,
    faCirclePlay,
    faSpinner,
    faRotateRight,
    faThumbtack,
    faThumbtackSlash,
    faXmark,
    faPlay,
    faStop,
    faCircleInfo)

const router = VueRouter.createRouter({
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
    history: VueRouter.createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'LoginPage',
            component: LoginPage
        },
        {
            path: '/home',
            name: 'MapPage',
            component: MapPage
        },
        {
            path: '/home/:bid',
            name: 'MapFollowBalise',
            component: MapPage
        },
        {
            path: '/offers',
            name: 'OffersPage',
            component: OffersPage
        },
        {
            path: '/balise/:id',
            name: 'BaliseInfo',
            component: BaliseInfo
        },
        {
            path: '/baliseconfig',
            name: 'BaliseConfig',
            component: BaliseConfig
        },
        {
            path: '/baliseslist',
            name: 'BalisesList',
            component: BalisesList
        },
        {
            path: '/baliseslistall',
            name: 'BalisesListAll',
            component: BalisesListAll
        },
        {
            path: '/groups',
            name: 'GroupsPage',
            component: GroupsPage
        },
        {
            path: '/users',
            name: 'UsersPage',
            component: UsersPage
        },
        {
            path: '/adminglobal',
            name: 'AdminGlobal',
            component: AdminGlobalPage
        },
        {
            path: '/geocoding',
            name: 'Geocoding',
            component: GeocodingPage
        },
        {
            path: '/faq',
            name: 'FAQPage',
            component: FAQPage
        },
        {
            path: '/contact',
            name: 'ContactPage',
            component: ContactPage
        },
        {
            path: '/serverstatus',
            name: 'ListenersStatus',
            component: ListenersStatus
        },
        {
            path: '/positions/:id',
            name: 'PositionsPage',
            component: PositionsPage
        },
        {
            path: '/drives/:id',
            name: 'DrivesPage',
            component: DrivesPage
        },
        {
            path: '/drivepositions/:id',
            name: 'DrivePositionsPage',
            component: DrivePositionsPage
        },
        {
            path: '/patchnotes',
            name: 'PatchNotes',
            component: PatchNotes
        },
        {
            path: '/report/balise/:id',
            name: 'ReportPage',
            component: ReportPage
        },
        {
            path: '/alerts/:id',
            name: 'AlertsPage',
            component: AlertsPage
        },
        {
            path: '/alertssms',
            name: 'AlertsSMSPage',
            component: AlertsSMSPage
        },
        {
            path: '/drivemap/:driveid',
            name: 'DriveMapPage',
            component: DriveMapPage
        },
        {
            path: '/balisecmd',
            name: 'BaliseCommand',
            component: BaliseCommand
        },
        {
            path: '/billing',
            name: 'BillingPage',
            component: BillingPage
        }
    ]
})


// Auto redirect to login page if no user detected
router.beforeEach((to) => {
    if(!store.state.auth.user && to.name !== 'LoginPage')
        return { name: 'LoginPage', query: { from: window.location.pathname } }
})


const app = createApp(App)

/**
* Global date management functions using moment
*/
moment.locale('fr')
app.config.globalProperties.$filters = {
    timeAgo(date) {
        if (moment(date).fromNow() == "Invalid date"){
            return "Aucune donnée"
        }
        else {
            return moment(date).fromNow()
        }
      },
    dayDateFormat(value){
        if (value) {
          return moment(String(value)).format('DD/MM/YYYY')
         }
     },
     secondDateFormat(value){
        if (value) {
          return moment(String(value)).format('DD/MM/YYYY à HH:mm:ss')
         }
     },
}

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)
app.use(store)
app.mount('#app')