<template>
    <div class="drive-component">

        <ul class="list-unstyled list-inline d-flex justify-content-between">
        <li class="list-inline-item me-0">
            <span v-if="baliseDrive && baliseDrive.startCity" class="small text-muted">{{ baliseDrive.startCity }}</span>
        </li>
        <li v-if="baliseDrive && baliseDrive.displayEndDate" class="list-inline-item me-0">
                <span v-if="baliseDrive && baliseDrive.duration" class="small text-muted">durée : {{ baliseDrive.duration }}</span>
                <span v-if="baliseDrive && baliseDrive.kilometers" class="small text-muted">, distance : {{ baliseDrive.kilometers }} km</span>
                <span v-if="baliseDrive && baliseDrive.maxSpeed" class="small text-muted">,  vitesse max : {{ baliseDrive.maxSpeed }} km/h</span>
        </li>
        <li class="list-inline-item me-0">
            <span v-if="baliseDrive && baliseDrive.endCity" class="small text-muted">{{ baliseDrive.endCity }}</span>
        </li>
        </ul>

        <div class="hrdivider">
        <hr class="border border-primary border-3"/>
        <span class="hrdivider-left"><font-awesome-icon :icon="['fas', 'fa-circle']" size="sm" class="text-primary" /></span>
        <span class="hrdivider-right"><font-awesome-icon :icon="['fas', 'fa-circle']" size="sm" class="text-primary" /></span>
        </div>

        <ul class="list-unstyled list-inline d-flex justify-content-between">
        <li class="list-inline-item me-0">
            <span v-if="baliseDrive && baliseDrive.displayStartDate" class="small text-muted">{{ baliseDrive.displayStartDate }}</span>
        </li>
        <li class="list-inline-item me-0">
            <span v-if="baliseDrive && baliseDrive.displayEndDate" class="small text-muted">{{ baliseDrive.displayEndDate }}</span>
        </li>
        </ul>

    </div>    
</template>

<script>
export default {
  props: ['baliseDrive']
}
</script>

<style>
.drive-component {
  width: 95%;
  padding-left: 5%;
}

.hrdivider {
  position: relative;
  margin-bottom: -10px;
  margin-top: -5px;
  width: 100%;
}

.hrdivider-left {
  position: absolute;
  top: -8px;
}

.hrdivider-right {
  position: absolute;
  top: -8px;
  right: 0;
}
</style>