<template>
    <DefaultHeader />
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-12">
                    <div class="d-flex flex-row justify-content-between card shadow my-2 p-3 bg-white rounded">
                        <div class="align-items-start">
                            <VehicleNameDisplay :balise="baliseInfo"></VehicleNameDisplay>
                        </div>
                        <div class="flex-fill align-items-center">
                            <FullDriveDisplay :baliseDrive="driveInfo" />
                        </div>
                        <div class="align-items-end">
                            <router-link :to="'/drivemap/' + driveInfo.id" class="text-decoration-none">
                                <img v-bind:src="require(`../assets/drivemap.png`)" class="rounded border border-info m-0 float-end"/>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <TemperatureChart v-if="this.baliseInfo.id"
                    :baliseId="this.baliseInfo.id"
                    :startDate="this.driveInfo.startDate"
                    :endDate="this.driveInfo.endDate"
                    :title="'Température du trajet'"/>
                </div>
                <div class="col-6">
                    <SpeedChart v-if="this.baliseInfo.id"
                    :baliseId="this.baliseInfo.id"
                    :startDate="this.driveInfo.startDate"
                    :endDate="this.driveInfo.endDate"
                    :title="'Vitesse du trajet'"/>
                </div>
            </div>
            <PositionsList v-if="this.baliseInfo.id"
            :baliseId="this.baliseInfo.id"
            :givenStartDate="this.driveInfo.startDate"
            :givenEndDate="this.driveInfo.endDate"
            :order="'ASC'"
            :displayCounter="true"/>
        </div>
    <DefaultFooter />
</template>

<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'
import TemperatureChart from '../components/charts/TemperatureChart.vue'
import SpeedChart from '../components/charts/SpeedChart.vue'
import PositionsList from '../components/PositionsList.vue'
import FormatHelper from '../helpers/format.helper.js'
import BaliseService from '../services/balise.service'
import DriveService from '../services/drive.service'
import VehicleNameDisplay from '../components/display/VehicleNameDisplay.vue'
import FullDriveDisplay from '../components/display/FullDriveDisplay.vue'


export default {
    components: {
        DefaultHeader,
        DefaultFooter,
        PositionsList,
        FullDriveDisplay,
        VehicleNameDisplay,
        TemperatureChart,
        SpeedChart
    },
    data() {
        return {
            baliseInfo: [],
            driveInfo: []
        }
    },
    mounted() {
        this.getDriveInfos()
    },
    methods: {
        getBaliseInfos(baliseId) {
            BaliseService.getBaliseInfo(baliseId)
                .then(response => {
                    this.baliseInfo = FormatHelper.baliseInfoFormat(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        },
        getDriveInfos() {
            DriveService.getDriveInfo(this.$route.params.id)
                .then(response => {
                    this.driveInfo = FormatHelper.driveInfoFormat(response.data)
                    this.getBaliseInfos(response.data.baliseId)
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }
}
</script>