<template>
<!-- Icon picker -->
<div class="modal fade" id="iconPicker" tabindex="-1" aria-labelledby="iconPickerModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable d-flex">
    <div class="modal-content m-auto">
      <div class="modal-header">
        <h5 class="modal-title" id="iconPickerModal">Icônes</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
        <span v-for="icon in icons.keys()"
            :key="icon">
            <input type="radio" v-model="chosenIcon" class="sr-only" :id=icon.slice(2) :value=icon.slice(2)>
            <label :for=icon.slice(2) class="icon-label p-1 m-1 rounded">
                <img :src="require(`../../assets/icons/` + icon.slice(2))">
            </label>
        </span>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" v-on:click="updateIcon()">Enregistrer</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import BaliseService from '../../services/balise.service.js'

export default {
    props: ['icons', 'baliseToUpdate'],
    data() {
        return {
            chosenIcon: null
        }
      },
    methods: {
        updateIcon() {
            BaliseService.updateIcon(this.baliseToUpdate, this.chosenIcon)
            .then(() => {
                this.$parent.refreshData();
            })
            .catch(e => {
                console.log(e);
            });
        }
    }
}
</script>

<style>
.modal-open {
  padding: 0 !important;
}
.modal.fade.show {
  z-index: 2000;
}
label.icon-label {
  cursor: pointer;
  transition: all .2s ease-in-out;
}

label.icon-label:hover {
  transform: scale(1.1);
}

input[type="radio"]:checked + label.icon-label {
  background-color: #adb5bd;
  box-shadow: 0 1rem 2rem rgba(0,0,0, .175);
  transform: scale(1.1);
}
</style>