<template>
    <DefaultHeader />
    <section class="container d-flex flex-column">
        <div class="row mb-2">
            <div class="col-12 col-md-6">
                <div class="card shadow p-3 mb-3 bg-white rounded">
                    <div class="card-header h5 bg-white">
                        Configuration
                    </div>
                    <div class="card-body">
                        <div class="form-check form-switch my-4">
                            <input v-model="allAlerts" class="form-check-input" type="checkbox" role="switch" id="allAlerts"
                                checked>
                            <label class="form-check-label" for="allAlerts">Activer les alertes SMS des
                                véhicules sélectionnés
                                <span class="text-muted">*</span>
                            </label>
                        </div>
                        <div>
                            <span class="my-4">SMS restants pour le mois en cours : 57</span>
                        </div>
                        <div v-if="allAlerts" class="mt-4">
                            <hr>
                            <div class="input-group mt-5">
                                <div class="input-group-prepend">
                                    <label class="input-group-text" for="alertSelect">Alerte</label>
                                </div>
                                <select class="form-select" aria-label="Default select example" id="alertSelect">
                                    <option v-for="(alert, index) in alertTypes" :key="index" :value="alert.id">{{ alert.name }}</option>
                                </select>
                            </div>
                            <div class="form-check form-switch my-4">
                                <input class="form-check-input" type="checkbox" role="switch" id="batteryAlert" checked>
                                <label class="form-check-label" for="batteryAlert">Activée</label>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer p-3 text-center bg-white">
                        <small class="text-muted">* Attention : activer les alertes SMS peut occasionner des dépassements.
                            Vérifiez votre offre auprès du service commercial.</small>
                    </div>
                </div>
            </div>

            <div v-if="allAlerts" class="col-12 col-md-6 d-flex flex-column justify-content-between h-100 h-md-auto">
                <div class="card shadow p-3 mb-3 bg-white rounded">
                    <div class="card-header h5 bg-white">
                        Numéros de réception
                    </div>
                    <div class="card-body">
                        <div class="input-group my-4">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">+33</span>
                            </div>
                            <input type="text" class="form-control" placeholder="" aria-label="number"
                                aria-describedby="basic-addon1" value="687280170">
                        </div>
                        <div class="input-group my-4">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">+33</span>
                            </div>
                            <input type="text" class="form-control" placeholder="" aria-label="number"
                                aria-describedby="basic-addon1" value="787284798">
                        </div>
                        <div class="input-group my-4">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">+33</span>
                            </div>
                            <input type="text" class="form-control" placeholder="" aria-label="number"
                                aria-describedby="basic-addon1" value="665731090">
                        </div>
                        <div class="form-check d-flex justify-content-center my-4">
                            <button type="submit" data-mdb-button-init data-mdb-ripple-init
                                class="btn btn-primary btn-block mb-4">
                                Ajouter un numéro
                            </button>
                        </div>
                        <div class="card-footer p-3 text-center bg-white">
                            <small class="text-muted">Attention : le nombre de SMS est multiplié par le nombre de numéros de
                                réception, pouvant occasionner des surconsommations de SMS. Pour tout renseignement,
                                contactez le service commercial.</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <DefaultFooter />
</template>
    
<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'
import AlertSMSService from '../services/alertSMS.service'
import { ref } from 'vue'


export default {
    components: {
        DefaultHeader,
        DefaultFooter
    },
    data() {
        return {
            alertTypes: [],
            allAlerts: ref(true)
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        this.getAlertTypes();
    },
    methods: {
        getAlertTypes() {
            AlertSMSService.getAlertTypes()
                .then(response => {
                    this.alertTypes = response.data
                })
        },
    }
}
</script>
