<template>
    <DefaultHeader />
    <div class="container-fluid">
        <div class="row d-flex justify-content-center">
            <div class="bg-light col-11 mb-lg-4 card mt-2 mt-md-2 p-3 rounded card-aside">
                <div class="row">
                    <div class="col d-flex">
                        <div><img class="me-2" :src="require(`../assets/green.png`)"></div>Tous les services
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="bg-white col-11 mb-lg-4 card mt-2 mt-md-2 p-3 rounded card-aside">
                <div class="row">
                    <div class="col d-flex">
                        <div><img class="me-2 img-fluid" :src="require(`../assets/green.png`)"></div>Server balises VT-10
                    </div>
                </div>
                <hr class="my-4" />
                <div class="row">
                    <div class="col d-flex">
                        <div><img class="me-2" :src="require(`../assets/red.png`)"></div>Server balises AT-35
                    </div>
                </div>
            </div>
        </div>
    </div>
    <DefaultFooter />
</template>


<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'


export default {
    components: {
        DefaultHeader,
        DefaultFooter
    }
}
</script>