<template>
    <DefaultHeader />

    <!-- Contact-->
    <section class="page-section" id="services">
        <div class="container px-4 px-lg-5">
            <h2 class="text-center mt-0">Des questions ?</h2>
            <hr class="divider" />
            <div class="text-center">
                Pour toute question concernant l'utilisation d'Argos, veuillez contacter l'administrateur de votre entreprise.
                En cas d'impossibilité, envoyez un e-mail au support Argos :
            </div>
            <div class="text-center my-5">
                <span class="border bg-light p-4">argos@sms-active.com</span>
            </div>
        </div>
    </section>

    <DefaultFooter />
</template>

<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'


export default {
    components: {
        DefaultHeader,
        DefaultFooter
    },
 }
</script>

<style>
.page-section {
  padding: 8rem 0;
}

hr.divider {
  height: 0.2rem;
  max-width: 3.25rem;
  margin: 1.5rem auto;
  background-color: #1363C6;
  opacity: 1;
}

.text-pimary-blue {
    color: #1363C6;
}
</style>