<template>
    <span v-if="alim == null || alim == 'undefined'">
        N.C
    </span>
    <span v-else-if="alim >= 7" class="badge text-nowrap bg-success fw-bold text-white align-bottom">
        <font-awesome-icon :icon="['fas', 'plug']" /> {{ alim }} v
    </span>
    <span v-else-if="alim < 7" class="badge text-nowrap bg-danger fw-bold text-white align-bottom">
        <font-awesome-icon :icon="['fas', 'plug']" /> {{ alim }} v
    </span>
    <span v-else>
        {{ alim }}
    </span>
</template>

<script>
export default {
  props: ['alim']
}
</script>