import axios from 'axios';
import authHeader from './auth-header';
import UserHelper from '../helpers/user.helper.js'


const API_URL = process.env.VUE_APP_API_URL+'balise/';

class BaliseService {

  getGroupBalisesLastPositions(search = null) {

    return search == null ?
    axios.get(API_URL + 'getclientbalises/' + UserHelper.getClientId(), { headers: authHeader() })
    :
    axios.get(
      API_URL + 'getclientbalises/' + UserHelper.getClientId(),
      { 
        params: { search: search },
        headers: authHeader()
      }
    );
  }

  getAllBalises(search = null) {

    return search == null ?
    axios.get(API_URL + 'getallbalises', { headers: authHeader() })
    :
    axios.get(
      API_URL + 'getallbalises/',
      { 
        params: { search: search },
        headers: authHeader()
      }
    );
  }

  getBaliseInfo(baliseId) {
    return axios.get(API_URL + 'getbaliseinfo/' + baliseId, { headers: authHeader() });
  }

  updateName(baliseId, name) {
    return axios.put(
        API_URL + 'update/' + baliseId,
        { name: name }, 
        { headers: authHeader() }
    );
  }

  updateGroup(baliseId, groupId) {
    return axios.put(
      API_URL + 'update/' + baliseId,
      { groupId: groupId },
      { headers: authHeader() }
    );
  }

  updateIcon(baliseId, icon) {
    return axios.put(
      API_URL + 'update/' + baliseId,
      { icon: icon },
      { headers: authHeader() }
    );
  }

  updateData(baliseId, data) {
    return axios.put(API_URL + 'update/' + baliseId, data, { headers: authHeader() });
  }
}

export default new BaliseService();