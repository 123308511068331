<template>
    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">{{ title }}</h6>
        </div>
        <div class="card-body">
            <GChart type="LineChart" :data="chartData" :options="this.chartOptions" />
            <div class="d-flex justify-content-between mt-2 hAxis-labels">
                <div class="label-date ms-5">
                    {{ displayStartDate }}
                </div>
                <div class="label-date me-5">
                    {{ displayEndDate }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GChart } from 'vue-google-charts'
import PositionService from '../../services/position.service'
import FormatHelper from '../../helpers/format.helper.js'



export default {
    props: ['baliseId', 'startDate', 'endDate', 'title'],
    components: {
        GChart
    },
    data() {
        return {
            // Array will be automatically processed with visualization.arrayToDataTable function
            chartData: [
                ['Date', 'Vitesse'],
                [this.startDate,0],
                [this.endDate,0]
            ],
            chartOptions: {
                curveType: 'function',
                vAxis: {
                    format: '#,##0 km/h',
                    minValue: 0,
                    viewWindow: {
                        min: 0,
                        max: 200
                    },
                    viewWindowMode: "explicit"
                },
                hAxis: {
                    textPosition: 'none'
                },
                legend: { position: 'top' },
                animation:{
                    duration: 1000,
                    easing: 'out',
                }
            }
        }
    },
    computed: {
        displayStartDate: function () {
            return FormatHelper.formatDate(this.startDate);
        },
        displayEndDate: function () {
            return FormatHelper.formatDate(this.endDate);
        }
    },
    mounted() {
        this.getPositions()
    },
    methods: {
        getPositions() {
            PositionService.getBalisePositions(this.baliseId, 0, 1000, this.startDate, this.endDate, "ASC")
                .then(response => {
                    this.chartData = FormatHelper.getChartSpeedData(response.data.rows);
                })
        },
    }
}
</script>

<style>
.label-date {
    font-size: 0.8em;
}

.hAxis-labels {
    position: relative;
    top: -10px;
}
</style>