<template>
    <div class="d-block d-md-none vh-100 d-flex">
        <p class="text-center h5 m-auto">Fonctionnalité indisponible sur mobile</p>
    </div>
    <div class="d-none d-md-block">
        <div class="row m-0">
            <div class="col-2">
                <div class="pt-2 ps-2 sticky-top">
                    <h4 class="text-center">Options du Rapport</h4>
                    <hr class="hr" />
                    <div>
                        <p>Trouver un trajet</p>
                        <div>
                            <div class="d-flex justify-content-center">
                                <button class="btn btn-primary m-1" @click="setYesterday">Hier</button>
                                <button class="btn btn-primary m-1" @click="setToday">Aujourd'hui</button>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button class="btn btn-primary m-1" @click="setLastWeek">La semaine dernière</button>
                            </div>
                        </div>
                        <form>
                                <div class="container">
                                    <div class="row mb-2">
                                        <div class="d-flex justify-content-between">
                                            <label for="dateDebut">Début : </label>
                                            <input class="mx-1 date" type="date" id="dateDebut" v-model="dates.startDate" required>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="d-flex justify-content-between">
                                            <label for="dateFin">Fin : </label>
                                            <input class="mx-1 date" type="date" id="dateFin" v-model="dates.endDate" required>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <button class="btn btn-primary" type="button" @click="baliseDatesDrives();">Appliquer</button>
                                    </div>
                                </div>
                            </form>
                    </div>
                    <hr class="hr" />
                    <div style="min-height: 180px;">
                        <p>Trajet pour le rapport</p>
                        <div v-if="baliseDrives[0]">
                            <div class="table-responsive p-3" style="height: 180px;">
                                <table class="table">
                                <thead>
                                    <tr>
                                    <th scope="col">Départ</th>
                                    <th scope="col"></th>
                                    <th scope="col" class="text-end">Arrivée</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="(baliseDrive, index) in baliseDrives[0]" :key="index">
                                    <td colspan="3" class="drive-anim" @click="displayDrivePositions(baliseDrive.startDate, baliseDrive.endDate)">

                                        <DriveDisplay :baliseDrive="baliseDrive" />

                                    </td>
                                    </tr>

                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <hr class="hr" />
                    <div>
                        <p>Données à afficher :</p>
                        <div class="form-check form-switch ps-0">
                            <label class="form-check-label" for="switchCoords">Coordonnées GPS</label>
                            <input class="form-check-input" type="checkbox" id="switchCoords" v-model="options.coords" style="float: right;">
                        </div>
                        <div class="form-check form-switch ps-0">
                            <label class="form-check-label" for="switchSpeed">Vitesse</label>
                            <input class="form-check-input" type="checkbox" id="switchSpeed" v-model="options.speed" style="float: right;">
                        </div>
                        <div class="form-check form-switch ps-0">
                            <label class="form-check-label" for="switchDirection">Direction</label>
                            <input class="form-check-input" type="checkbox" id="switchDirection" v-model="options.direction" style="float: right;">
                        </div>
                        <div class="form-check form-switch ps-0">
                            <label class="form-check-label" for="switchGSM">Réseau GSM</label>
                            <input class="form-check-input" type="checkbox" id="switchGSM" v-model="options.gsm" style="float: right;">
                        </div>
                        <div class="form-check form-switch ps-0">
                            <label class="form-check-label" for="switchGPS">Réseau GPS</label>
                            <input class="form-check-input" type="checkbox" id="switchGPS" v-model="options.gps" style="float: right;">
                        </div>
                    </div>
                    <hr class="hr" />
                    <div class="d-flex justify-content-center p-2">
                        <button class="btn btn-primary " @click="generatePDF(this.totalPages)" :disabled="!this.baliseDrives[0]">Télécharger</button>
                    </div>
                </div>
            </div>
            <div class="col-10 bg-secondary-subtle d-flex justify-content-center">
                <TemplatePDFPositions ref="pdfContent" :balisePositions="balisePositions" :baliseInfo="baliseInfo" :options="options" :dates="dates" :driveDates="driveDates" :totalPages="totalPages" />
            </div>
        </div>
    </div>

</template>

<script>
import jsPDF from 'jspdf'
import TemplatePDFPositions from '../positions/TemplatePDFPositions.vue';
import BaliseService from '../../../services/balise.service'
import FormatHelper from '../../../helpers/format.helper.js'
import DriveService from '../../../services/drive.service'
import DriveDisplay from '../../../components/display/DriveDisplay.vue'
import PositionService from '../../../services/position.service';


export default {
    name: 'PDFDrivesReport',
    props: ['baliseId'],
    components: {
        TemplatePDFPositions,
        DriveDisplay
    },
    data() {
        return {
            baliseDrives: [],
            baliseInfo: [],
            balisePositions: [],
            options: {
                'coords': true,
                'speed': true,
                'direction': true,
                'gsm': true,
                'gps': true
            },
            dates: {
                'startDate': new Date().toISOString().split('T')[0],
                'endDate': new Date().toISOString().split('T')[0]
            },
            driveDates: {
                'driveStartDate': null,
                'driveEndDate': null
            },
            totalPages: 0,
            perPage: 10
        }
    },
    mounted() {
        this.refreshData()
        this.baliseDatesDrives()
    },
    watch: {
        'dates.startDate': function (val) {
            const startDate = new Date(val);
            const endDate = new Date(this.dates.endDate);

            if (startDate > endDate) {
                this.dates.endDate = val;
            } else if ((endDate - startDate) / (1000 * 3600 * 24) > 7) {
                let newEndDate = new Date(startDate);
                newEndDate.setDate(newEndDate.getDate() + 7);
                this.dates.endDate = newEndDate.toISOString().split('T')[0];
            }
            },
            'dates.endDate': function (val) {
            const startDate = new Date(this.dates.startDate);
            const endDate = new Date(val);

            if (endDate < startDate) {
                this.dates.startDate = val;
            } else if ((endDate - startDate) / (1000 * 3600 * 24) > 7) {
                let newStartDate = new Date(endDate);
                newStartDate.setDate(newStartDate.getDate() - 7);
                this.dates.startDate = newStartDate.toISOString().split('T')[0];
            }
        },
    },
    methods: {
        refreshData() {
            BaliseService.getBaliseInfo(this.baliseId)
                .then(response => {
                this.baliseInfo = FormatHelper.baliseInfoFormat(response.data);
                })
                .catch(e => {
                console.log(e);
                });
        },
        baliseDatesDrives() {
            this.baliseDrives = []
            DriveService.getBaliseDrives(this.baliseId, 0, 1000 , this.dates.startDate, this.dates.endDate)
                .then(response => {
                    this.drivesCount = response.data.count
                    for (let i in response.data.rows) {
                        this.baliseDrives[i] = FormatHelper.baliseDrivesFormat(response.data.rows)
                    }
                })
                .catch(e => {
                    console.log(e)
                })
        },
        displayDrivePositions(startDate, endDate) {
            this.balisePositions = [];
            this.driveDates.startDate = startDate
            this.driveDates.endDate = endDate
            console.log("variable driveDates.startDate = "+this.driveDates.startDate)
            console.log('variable driveDate.endDate = '+this.driveDates.endDate)
            PositionService.getBalisePositions(this.baliseId, 0, this.perPage, startDate, endDate)
            .then(response => {
                this.positionsCount = response.data.count;
                this.totalPages = Math.ceil(this.positionsCount / this.perPage);
                return Promise.all(Array.from({ length: this.totalPages }, (_, i) => 
                PositionService.getBalisePositions(this.baliseId, i * this.perPage, this.perPage, startDate, endDate)
                ));
            })
            .then(results => {
                results.forEach((response, i) => {
                this.balisePositions[i] = FormatHelper.balisePingsFormat(response.data.rows);
                });
            })
            .catch(e => {
                console.log(e);
            });
        },
        generatePDF(totalPages) {

            // Récupération du contenu HTML du template
            const pdfTemplate = document.getElementById('pdf-template')
            
            let fileName = "Rapport du trajet - "+this.baliseInfo.name+" - du "+new Date(this.driveDates.startDate).toLocaleString('fr-FR')+" au "+new Date(this.driveDates.endDate).toLocaleString('fr-FR')

            // Création d'une nouvelle instance de jsPDF
            const doc = new jsPDF('p','pt','a4');
            const options = {
                pagesplit: true, // Permet de diviser les pages si le contenu dépasse
            }

            //test
            let pageHeight = doc.internal.pageSize.height
            let pageWidth = doc.internal.pageSize.width
            console.log('Page height = '+pageHeight)
            console.log('Page widtht = '+pageWidth)
            console.log('Content height = '+pdfTemplate.offsetHeight)
            console.log('Content width = '+pdfTemplate.offsetWidth)

            // Convertir le contenu HTML en PDF
            doc.html(pdfTemplate, {
                options,
                autoPaging: 'text',
                html2canvas: {
                    scale: 0.725
                },
                callback: function (doc) {
                // Sauvegarde du PDF
                console.log('totalpages = '+totalPages)
                let pageCount = doc.internal.getNumberOfPages()
                for (pageCount; pageCount > totalPages; pageCount--){
                    doc.deletePage(pageCount)
                }
                doc.save(fileName.toString()+'.pdf');
                }
            })
        },
        setYesterday() {
            let date = new Date()
            date.setDate(date.getDate() - 1)
            let updatedStartDate = date.toISOString().split('T')[0].replaceAll("/", "-")
            console.log(updatedStartDate)
            this.dates.startDate = updatedStartDate
            this.dates.endDate = updatedStartDate
            this.baliseDatesDrives()
        },
        setToday() {
            let date = new Date()
            let updatedStartDate = date.toISOString().split('T')[0].replaceAll("/", "-")
            console.log(updatedStartDate)
            this.dates.startDate = updatedStartDate
            this.dates.endDate = updatedStartDate
            this.baliseDatesDrives()
        },
        setLastWeek() {
            let date = new Date()
            date.setDate(date.getDate() - 7)
            let updatedStartDate = date.toISOString().split('T')[0].replaceAll("/", "-")
            console.log(updatedStartDate)
            this.dates.startDate = updatedStartDate
            this.dates.endDate = new Date().toISOString().split('T')[0].replaceAll("/", "-")
            this.baliseDatesDrives()
        },
    }
}
</script>
<style>
.drive-anim {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  padding: 14px 80px 18px 36px;
  cursor: pointer;
}
.drive-anim:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
  transition: 0.3s;
}
</style>