import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL+'drive/';

class DriveService {

  getBaliseDrives(baliseId, offset, limit, startPage = null, endPage = null) {

    var UrlToCall;
    UrlToCall = API_URL + 'getbalisedrives/'+ baliseId + '?offset=' + offset + '&limit=' + limit;
    UrlToCall = (startPage != null) ? (UrlToCall + '&startDate=' + startPage) : UrlToCall;
    UrlToCall = (endPage != null) ? (UrlToCall + '&endDate=' + endPage) : UrlToCall;
    
    return axios.get(UrlToCall, { headers: authHeader() });
  }

  getDriveInfo(driveId) {
    return axios.get(API_URL + 'getdriveinfo/' + driveId, { headers: authHeader() });
  }

}

export default new DriveService();