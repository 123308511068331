<template>
<tr :class="{ 'd-none' : this.hideDefault }">
    <td>
        <VehicleNameDisplay :balise="balise"></VehicleNameDisplay>
    </td>
    <td :class="{
        'bg-danger': (new Date(Date.now()-86400000).toISOString() > balise.updatedAt),
        'text-white': (new Date(Date.now()-86400000).toISOString() > balise.updatedAt)
    }">{{ $filters.timeAgo(balise.lastSeen) }}</td>
    <td>
        <div><span><BatteryDisplay :battery="balise.lastBattery" /></span></div>
        <div class="mt-2"><span><AlimDisplay :alim="balise.lastPowerVoltage" /></span></div>
    </td>
    <td>
        <div><span>{{ balise.lastAddress }}</span></div>
        <div><LatLongDisplay :latitude="balise.lastLatitude" :longitude="balise.lastLongitude"></LatLongDisplay></div>
    </td>
    <td>
        <div><span>GSM : <GSMDisplay :gsm="balise.lastLevelGSM" /></span></div>
        <div class="mt-2"><span>GPS : <GPSDisplay :gps="balise.lastLevelGPS"></GPSDisplay></span></div>
    </td>
    <td>{{ balise.lastSpeed }} km/h</td>
    <td class="text-center"><TemperatureDisplay :temperature="balise.lastTemperature"></TemperatureDisplay></td>
    <td v-if="!this.isOperator && !this.isViewer" class="text-end">
        <div>
            <a data-bs-toggle="dropdown" href="#" class="btn p-1" aria-expanded="false">
                <font-awesome-icon :icon="['fas', 'bars']"/>
            </a>
            <div class="dropdown-menu dropdown-menu-end" style="">
                <router-link :to="'/balise/' + balise.id " class="dropdown-item">Infos détaillées</router-link>
                <a href="#!" v-on:click="this.hideDefault=true;this.hideName=false" class="dropdown-item">Changer le nom</a>
                <a v-if="this.groups != null" href="#!" v-on:click="this.hideDefault=true;this.hideGroup=false" class="dropdown-item">Changer le groupe</a>
                <a href="#!" v-on:click="changeBalise(balise.id)" data-bs-toggle="modal" data-bs-target="#iconPicker" class="dropdown-item">Changer l'icône</a>
            </div>
        </div>
    </td>
    <td v-else class="text-center">
        <div class="btn p-1">
            <font-awesome-icon :icon="['fas', 'ban']"/>
        </div>
    </td>
</tr>
<tr v-if="this.groups != null" :class="{ 'd-none' : this.hideGroup }">
    <td>
        <div class="d-flex align-items-center">
            <img :src="require(`../../assets/icons/` + balise.icon)"
                class="avatar sm rounded-pill me-3 flex-shrink-0" alt="Customer">
            <div>
                <div class="h6 mb-0 lh-1">{{ balise.name }}</div>
            </div>
        </div>
    </td>
    <td class="text-center" colspan="3">
        <form @submit.prevent class="row g-3">
            <div class="col-auto">
                <select v-model="group" class="form-select">
                    <option
                        v-for="group in 
                        groups"
                        :key="group.id"
                        :value=group.id>{{ group.name }}</option>
                </select>
            </div>
            <div class="col-auto">
                <button class="btn btn-primary btn-block" v-on:click="this.hideGroup=true;this.hideDefault=false;updateGroup(this.balise.id)">Valider</button>
            </div>
            <div class="col-auto">
                <button class="btn btn-danger btn-block" v-on:click="this.hideGroup=true;this.hideDefault=false">Annuler</button>
            </div>
        </form>
    </td>
</tr>
<tr :class="{ 'd-none' : this.hideName }">
    <td>
        <div class="d-flex align-items-center">
            <img :src="require(`../../assets/icons/` + balise.icon)"
                class="avatar sm rounded-pill me-3 flex-shrink-0" alt="Customer">
            <div>
                <div class="h6 mb-0 lh-1">{{ balise.name }}</div>
            </div>
        </div>
    </td>
    <td class="text-center" colspan="3">
        <form @submit.prevent class="row g-3">
            <div class="col-auto">
                <input v-model="name" type="text" class="form-control" placeholder="Nouveau nom">
            </div>
            <div class="col-auto">
                <button class="btn btn-primary btn-block" v-on:click="this.hideName=true;this.hideDefault=false;updateName(this.balise.id)">Valider</button>
            </div>
            <div class="col-auto">
                <button class="btn btn-danger btn-block" v-on:click="this.hideName=true;this.hideDefault=false">Annuler</button>
            </div>
        </form>
    </td>
</tr>

</template>


<script>
import BatteryDisplay from '../display/BatteryDisplay.vue'
import AlimDisplay from '../display/AlimDisplay.vue'
import GSMDisplay from '../display/GSMDisplay.vue'
import GPSDisplay from '../display/GPSDisplay.vue'
import TemperatureDisplay from '../display/TemperatureDisplay.vue'
import VehicleNameDisplay from '../display/VehicleNameDisplay.vue'
import LatLongDisplay from '../display/LatLongDisplay.vue'
import BaliseService from '../../services/balise.service.js'
import UserHelper from '../../helpers/user.helper.js'

export default {
    props: ['balise', 'groups'],
    components: {
        BatteryDisplay,
        AlimDisplay,
        GSMDisplay,
        GPSDisplay,
        LatLongDisplay,
        VehicleNameDisplay,
        TemperatureDisplay
    },
    data() {
        return {
            hideDefault: false,
            hideName: true,
            hideGroup: true
        };
    },
    computed: {
        isAdmin() {
            return UserHelper.isAdmin(this.$store.state.auth.user);
        },
        isViewer() {
            return UserHelper.isViewer(this.$store.state.auth.user);
        },
        isOperator() {
            return UserHelper.isOperator(this.$store.state.auth.user);
        },
    },
    methods: {
        updateName(bId) {
            BaliseService.updateName(bId, this.name)
            .then(() => {
                this.$parent.refreshData();
            })
            .catch(e => {
                console.log(e);
            });
        },
        updateGroup(bId) {
            BaliseService.updateGroup(bId, this.group)
            .then(() => {
                this.$parent.refreshData();
            })
            .catch(e => {
                console.log(e);
            });
        },
        changeBalise(bId) {
            this.$emit("param", bId);
        }
    }
}
</script>

<style>
div.dropdown-menu.open {
    position: absolute;
}
</style>