<template>
    <div class="text-center">
        <img  v-if="isIcon" :src="require(`../../assets/icons/${icon}`)" />
        <font-awesome-icon v-else :icon="['fas', 'spinner']" size="2xl" spin />
        <font-awesome-icon v-if="this.alarm" :icon="['fas', 'circle']" class="alarm" size="6x" />
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: true
        },
        alarm: {
            default: false
        }
    },
    computed: {
        isIcon() {
            try {
                require(`../../assets/icons/${this.icon}`)
                return true
            } catch (error) {
                console.log("Erreur de chargement de l'icone du véhicule, chargement de l'icone par défaut")
                return false
            }
        }
    }
}
</script>

<style>
@keyframes alarm {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 0.6;
  }
}

.alarm {
  animation-duration: 0.5s;
  animation-name: alarm;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.alarm {
    color: rgba(224,22,22,0.8);
    z-index:2;
    position: relative;
    top: -60px;
}

.vehicle-icon {
    z-index: 4;
}
</style>

