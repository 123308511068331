<template>
  <div class="vh-100 vw-100 d-flex flex-column">
    <DefaultHeader :noMargin="true"/>
    <MainMap />
  </div>
</template>

<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import MainMap from '../components/map/MainMap.vue'

export default {
    components: {
        DefaultHeader,
        MainMap
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user
        }
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/')
        }
    }
}
</script>