import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL+'billing/';

class BillingService {
    getClientsBillings() {
        return axios.get(API_URL + 'getclientbillings', { headers: authHeader() });
    }
}

export default new BillingService();