<template>
  <l-marker v-bind:lat-lng="[balise.lastLatitude, balise.lastLongitude]">
    <!--
    <l-tooltip>
      <h6>{{ name }}</h6>
      <br>Mise à jour : {{ $filters.timeAgo(lastSeen) }}
      <br>Dernière position :
      <br>{{ address }}
    </l-tooltip>
    -->
    <l-icon class-name="someExtraClass"
      :icon-size=[96,48]
      :icon-anchor=[48,24]>
      <VehicleIcon :icon="balise.icon" :alarm="false"/>
      <div class="text-center bg-info bg-opacity-50 rounded" style="width: 96px">{{ balise.name }}</div>
    </l-icon>
    <l-popup v-if="!isLargeScreen" id="markerLPopup">
      <ul class="list-unstyled list-inline d-flex justify-content-between">
        <li class="list-inline-item">
          <h6 class="mb-0">{{ balise.name }}</h6>
          <div class="text-muted small mb-1">
            {{ balise.immat }}
          </div>
          <button v-if="!this.followMode" class="btn btn-sm btn-primary"  v-on:click="followBalise()">Suivre ce véhicule</button>
          <button v-else class="btn btn-sm btn-warning"  v-on:click="unFollowBalise()">Arrêter le suivi</button>
        </li>
        <li class="list-inline-item">
          <BatteryDisplay :battery="balise.lastBattery" />
        </li>
      </ul>
      <ul class="list-unstyled list-inline d-flex justify-content-between">
        <li class="list-inline-item">
          <router-link :to="'/balise/' + balise.id">
            Détail du véhicule <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" class="small" />
          </router-link>
          <br>Mise à jour : {{ $filters.timeAgo(balise.lastSeen) }}
          <br>{{ balise.lastAddress }}
          <br><router-link :to="'/drives/' + balise.id">Historique</router-link>
        </li>
        <li class="list-inline-item">
          {{ balise.lastSpeed }} km/h
          <div class="text-nowrap my-1">GSM : <GSMDisplay :gsm="balise.lastLevelGSM" /></div>
          <div class="text-nowrap my-1">GPS : <GPSDisplay :gps="balise.lastLevelGPS"></GPSDisplay></div>
          <div class="text-nowrap my-1">Alim : <AlimDisplay :alim="balise.lastPowerVoltage" /></div>
          <div class="text-nowrap my-1">Temp. système : <TemperatureDisplay :temperature="balise.lastTemperature"></TemperatureDisplay></div>
        </li>
      </ul>
      <div v-if="this.balise.allowedSMS" class="form-check form-switch">
        <input v-model="this.smsStatus" v-on:change="updateBaliseSMSStatus()" class="form-check-input"
          type="checkbox" role="switch" id="allAlerts" checked>
        <label class="form-check-label" for="allAlerts">Activer les alertes Textos pour ce véhicule</label>
      </div>
    </l-popup>
  </l-marker>
</template>
  
<script>
import BaliseService from '../../services/balise.service'
import BatteryDisplay from '../display/BatteryDisplay.vue'
import GSMDisplay from '../display/GSMDisplay.vue'
import GPSDisplay from '../display/GPSDisplay.vue'
import AlimDisplay from '../display/AlimDisplay.vue'
import TemperatureDisplay from '../display/TemperatureDisplay.vue'
import { LMarker, LIcon, LPopup } from "@vue-leaflet/vue-leaflet";
import VehicleIcon from '@/components/display/VehicleIcon.vue'

export default {
  props: ['followMode', 'balise'],
  components: {
    LMarker,
    LIcon,
    LPopup,
    BatteryDisplay,
    GSMDisplay,
    AlimDisplay,
    GPSDisplay,
    TemperatureDisplay,
    VehicleIcon
  },
  data() {
    return {
      smsStatus: null,
      isLargeScreen: false
    };
  },
  mounted() {
    this.smsStatus = this.balise.alertSMS;
    console.log(this.balise);
    this.updateScreenSize();
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenSize);
  },
  methods: {
    followBalise() {
      this.$emit("bId", this.balise.id);
    },
    unFollowBalise() {
      this.$router.push('/');
    },
    // Activate / unactivate the overall SMS alert for the current balise
    updateBaliseSMSStatus() {
      BaliseService.updateData(this.balise.id, { alertSMS: this.smsStatus });
    },
    updateScreenSize() {
      this.isLargeScreen = window.innerWidth > 900;
    }
  }
}
</script>