<template>
    <div class="vh-100 vw-100 d-flex flex-column">
      <DefaultHeader :noMargin="true"/>
      <HistoryMap />
    </div>
  </template>
  
  <script>
  import DefaultHeader from '../components/DefaultHeader.vue'
  import HistoryMap from '../components/map/HistoryMap.vue'
  
  export default {
      components: {
          DefaultHeader,
          HistoryMap
      },
      computed: {
          currentUser() {
              return this.$store.state.auth.user
          }
      },
      mounted() {
          if (!this.currentUser) {
              this.$router.push('/')
          }
      }
  }
  </script>