<template>
    <DefaultHeader />
    <div class="container-fluid">
        <div class="row d-flex justify-content-center mb-2">
            <div class="col-10 col-md-2">
                <input v-model="searchInput" type="text" class="form-control" placeholder="Chercher (nom, immat)...">
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-11 mb-3 mb-lg-5">
                <div class="card table-nowrap table-card">
                    <div class="table-responsive">
                        <table class="table mb-0 table-striped">
                            <thead class="small text-uppercase bg-body text-muted">
                                <tr>
                                    <th class="align-middle">Véhicules</th>
                                    <th class="align-middle">Actualisation</th>
                                    <th class="align-middle">Alimentation</th>
                                    <th class="align-middle">Position</th>
                                    <th class="align-middle">Réception</th>
                                    <th class="align-middle">Vitesse</th>
                                    <th class="align-middle">Temp.<br>système</th>
                                    <th class="align-middle text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <TableRow 
                                    v-for="balise in balises"
                                    :key="balise.id"
                                    :balise="balise"
                                    :groups=null
                                    :icons="icons"
                                    @param="setBaliseToUpdate" />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <IconPickerModal :icons="icons" :baliseToUpdate="baliseToUpdate"/>
    <DefaultFooter />
</template>
    
<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'
import IconPickerModal from '../components/balisesPage/IconPickerModal.vue'
import BaliseService from '../services/balise.service.js'
import TableRow from '../components/balisesPage/TableRow.vue'
import FormatHelper from '../helpers/format.helper.js'


export default {
    components: {
        DefaultHeader,
        DefaultFooter,
        TableRow,
        IconPickerModal
    },
    data() {
        return {
            balises: null,
            icons : require.context('../assets/icons/', false, /^.*\.png$/),
            baliseToUpdate : null,
            searchInput: null
        };
    },
    mounted() {
        this.refreshData();
    },
    watch: { 
        searchInput: function() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.refreshData();
            }, 400);
        }
    },
    methods: {
        refreshData() {
            BaliseService.getAllBalises(this.searchInput)
                .then(response => {
                    this.balises = FormatHelper.balisesResponseFormat(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        },
        setBaliseToUpdate(param) {
            this.baliseToUpdate = param;
        }
    }
}
</script>