<template>
    <div v-if="!balisePositions.length" class="vh-100 d-flex align-items-center">
        <h1 class="text-center">Aucune données à afficher pour cette période</h1>
    </div>
    <div v-else id="pdf-template">
        <div v-for="index in totalPages" :key="index" :ref="`page${index}`">
            <div :ref="`pageRef${index}`" class="p-4 shadow my-4 d-flex flex-column" style="width: 210mm; background-color: #fff; height: 301mm;">
                <div class="d-flex justify-content-between">
                    <img :src="require('../../../assets/sms-logo.png')" alt="Company Logo" class="img-fluid" id="logo">
                    <div>page {{ index }}/{{ totalPages }}</div>
                </div>
                <div v-if="index == 1">
                    <h2 class="text-center py-4">Positions du trajet</h2>
                    <h4 class="text-center mb-4">Trajet du {{ new Date(driveDates.startDate).toLocaleString('fr-FR') }} au {{ new Date(driveDates.endDate).toLocaleString('fr-FR') }}</h4>
                    <p><strong>Nom du véhicule:</strong> {{ baliseInfo.name }}</p>
                    <p><strong>Groupe du véhicule:</strong> {{ baliseInfo.groupName }}</p>
                    <p><strong>IMEI de la balise:</strong> {{ baliseInfo.imei }}</p>
                </div>
                <div class="d-flex flex-grow-1 mt-5">
                    <div class="flex-grow-1">
                        <table class="table table-sm mt-4">
                            <thead class="table-dark">
                                <tr>
                                    <th>Date et heure</th>
                                    <th>Adresse <span v-if="options.coords">et Coordonnées GPS</span></th>
                                    <th v-if="options.speed">Vitesse</th>
                                    <th v-if="options.direction">Direction</th>
                                    <th v-if="options.gsm">GSM</th>
                                    <th v-if="options.gps">GPS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(balisePosition, index2) in balisePositions[index - 1]" :key="index2">
                                    <td>{{ balisePosition.date }}</td>
                                    <td>{{ balisePosition.address }}<br><span v-if="options.coords"><i><small class="text-muted">{{ balisePosition.latitude }}, {{ balisePosition.longitude }}</small></i></span></td>
                                    <td v-if="options.speed">{{ balisePosition.speed }} km/h</td>
                                    <td v-if="options.direction">{{ balisePosition.direction }}°</td>
                                    <td v-if="options.gsm">{{ 5*balisePosition.levelGSM/100 }}/5</td>
                                    <td v-if="options.gps">{{ balisePosition.levelGPS }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        balisePositions: {
            type: Array,
            required: true
        },
        baliseInfo: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            required: true
        },
        dates: {
            type: Object,
            required: true
        },
        driveDates: {
            type: Object,
            required: true
        },
        totalPages: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>
#pdf-template {
    font-family: Arial, sans-serif;
    margin: 20px;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

p {
    margin-bottom: 10px;
}
small {
    font-size: x-small;
}
</style>