<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    document.title = "Argos";
  }
}
</script>

<style>
/* Update the bootstrap elements styles */
.btn-primary {
  color: #fff !important;
  background: #386bc0 !important;
  border-color: #386bc0 !important;
  box-shadow: 0 8px 9px -4px rgba(59, 113, 202, 0.3), 0 4px 18px 0 rgba(59, 113, 202, 0.2) !important;
}

.btn-primary:hover {
  background: #386bc0 !important;
  border-color: #386bc0 !important;
}
</style>
