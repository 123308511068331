<template>
    <div v-if="alert.alertTypeId == 1">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Début trajet</div>
    </div>
    <div v-else-if="alert.alertTypeId == 2">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Fin trajet</div>
    </div>
    <div v-else-if="alert.alertTypeId == 3">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Débranchement</div>
    </div>
    <div v-else-if="alert.alertTypeId == 4">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Batterie balise faible</div>
    </div>
    <div v-else-if="alert.alertTypeId == 5">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">USB connecté</div>
    </div>
    <div v-else-if="alert.alertTypeId == 6">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">USB déconnecté</div>
    </div>
    <div v-else-if="alert.alertTypeId == 7">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Branchement</div>
    </div>
    <div v-else-if="alert.alertTypeId == 8">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Vibration</div>
    </div>
    <div v-else-if="alert.alertTypeId == 9">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Fin de vibration</div>
    </div>
    <div v-else-if="alert.alertTypeId == 10">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Collision</div>
    </div>
    <div v-else-if="alert.alertTypeId == 11">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Fin de collision</div>
    </div>
    <div v-else-if="alert.alertTypeId == 12">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Brouillage</div>
    </div>
    <div v-else-if="alert.alertTypeId == 13">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Fin de brouillage</div>
    </div>
    <div v-else-if="alert.alertTypeId == 14">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Dépassement de vitesse</div>
    </div>
    <div v-else-if="alert.alertTypeId == 15">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Batterie véhicule faible</div>
    </div>
    <div v-else-if="alert.alertTypeId == 16">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Remorquage</div>
    </div>
    <div v-else-if="alert.alertTypeId == 17">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Anti-vol</div>
    </div>
    <div v-else-if="alert.alertTypeId == 18">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Température élevée</div>
    </div>
    <div v-else-if="alert.alertTypeId == 19">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Fin température élevée</div>
    </div>
    <div v-else-if="alert.alertTypeId == 20">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Température basse</div>
    </div>
    <div v-else-if="alert.alertTypeId == 21">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Fin température basse</div>
    </div>
    <div v-else-if="alert.alertTypeId == 22">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Accélération brutale</div>
    </div>
    <div v-else-if="alert.alertTypeId == 23">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Freinage brutal</div>
    </div>
    <div v-else-if="alert.alertTypeId == 24">
        <div class="text-center text-danger"><font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="xl" /></div>
        <div class="text-center">Virage brutal</div>
    </div>
    <div v-else>
        N.C
    </div>
</template>

<script>
export default {
  props: ['alert']
}
</script>