import axios from 'axios';
import authHeader from './auth-header';
import UserHelper from '../helpers/user.helper.js'

const API_URL = process.env.VUE_APP_API_URL+'auth/';

class AuthService {
  login(user) {
    return axios.post(API_URL + 'login', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('clientIdToWatch');
    localStorage.removeItem('clientNameToWatch');
    localStorage.removeItem('clients');
    UserHelper.currentUser = null;
  }

  createUser(email, password, role) {
    return axios.put(API_URL + 'createclientuser/' + UserHelper.getClientId(), {
      email: email,
      password: password,
      role: role
    },
    { headers: authHeader() });
  }

  updatePassword(userId, password) {
    return axios.put(API_URL + 'updateuserpassword/' + userId, {
      password: password
    },
    { headers: authHeader() });
  }
}

export default new AuthService();