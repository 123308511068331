<template>
    <span v-if="gps == null || gps == 'undefined'">
        N.C
    </span>
    <span v-else-if="!Number.isInteger(gps)">
        {{ gps }}
    </span>
    <span v-else-if="gps >= 10" class="badge text-nowrap bg-success fw-bold text-white align-baseline">
        <font-awesome-icon :icon="['fas', 'globe']" /> {{ gps }} sat
    </span>
    <span v-else-if="gps >= 5" class="badge text-nowrap bg-warning text-dark fw-bold align-baseline">
        <font-awesome-icon :icon="['fas', 'globe']" /> {{ gps }} sat
    </span>
    <span v-else class="badge text-nowrap bg-danger fw-bold text-white align-baseline">
        <font-awesome-icon :icon="['fas', 'globe']" /> {{ gps }} sat
    </span>
</template>

<script>
export default {
  props: ['gps']
}
</script>