<template>
  <section class="background-radial-gradient overflow-hidden min-vh-100">
    <div class="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
      <div class="row gx-lg-5 align-items-center mb-5">
        <div class="col-lg-6 mb-5 mb-lg-0" style="z-index: 10">
          <h1 class="my-5 display-5 fw-bold ls-tight" style="color: hsl(218, 81%, 95%)">
            Argos <br />
            <span style="color: hsl(218, 81%, 75%)">Geolocalisation</span>
          </h1>
          <p class="mb-4 opacity-70" style="color: hsl(218, 81%, 85%)">
            Argos solution de géolocalisation et télésurveillance de flottes et véhicules.</p>
            <hr style="color: hsl(218, 81%, 75%)" class="hr" />
            <p class="mb-4 opacity-70" style="color: hsl(218, 81%, 85%)">
            Pour obtenir des identifiants de connexion, contactez votre administrateur.
            <br>Si vous n'êtes pas client, retrouvez-nous sur <a class="link-light" href="https://sms-active.com">sms-active.com</a>
          </p>
        </div>

        <div class="col-lg-6 mb-5 mb-lg-0 position-relative">
          <div id="radius-shape-1" class="position-absolute rounded-circle shadow-5-strong"></div>
          <div id="radius-shape-2" class="position-absolute shadow-5-strong"></div>

          <div class="card bg-glass">
            <div class="card-body px-4 py-5 px-md-5">
              <h1 class="h3 mb-5 font-weight-normal">Connexion</h1>

              <Form @submit="handleLogin" :validation-schema="schema" class="form-signin">

                <!-- Email input -->
                <div data-mdb-input-init class="form-outline mb-4">
                  <Field name="email" id="emailInput" type="text" class="form-control" placeholder="Adresse e-mail" />
                  <ErrorMessage name="email" class="text-danger" />
                </div>

                <!-- Password input -->
                <div data-mdb-input-init class="form-outline mb-4">
                  <Field name="password" id="passwordInput" type="password" class="form-control"
                    placeholder="Entrez votre mot de passe" />
                  <ErrorMessage name="password" class="text-danger" />
                </div>

                <!-- Error message -->
                <div v-if="this.message !== ''" class="alert alert-danger">
                    {{ this.message }}
                </div>

                <!-- Checkbox -->
                <div class="form-check d-flex justify-content-center my-4">
                  <input class="form-check-input me-2" type="checkbox" value="remember-me" checked />
                  <label class="form-check-label" for="form2Example33">
                    Se souvenir de moi
                  </label>
                </div>

                <!-- Submit button -->
                <div class="form-check d-flex justify-content-center my-4">
                  <button type="submit" data-mdb-button-init data-mdb-ripple-init class="btn btn-primary btn-block mb-4">
                    Connexion
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup.string().required("Une adresse e-mail est requise"),
      password: yup.string().required("Veuillez entrer un mot de passe"),
    });

    return {
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/home");
    }
  },
  methods: {
    handleLogin(user) {
      this.$store.dispatch("auth/login", user)
        .then(() => {
          this.$router.push(this.$route.query.from || '/home')
        },
          (error) => {
            if(error.response.request.status == 401 || error.response.request.status == 404) {
              this.message = "Erreur de connexion, veuillez vérifier vos coordonnées"
            }
          }
        );
    },
  },
};
</script>
  
<style>
.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background-image: url('../assets/logscreen.jpg')
}

#radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -60px;
  left: -130px;
  background: radial-gradient(#0260a3, #63a9ff);
  overflow: hidden;
}

#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -60px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#0260a3, #63a9ff);
  overflow: hidden;
}

.bg-glass {
  background-color: hsla(0, 0%, 100%, 0.9) !important;
  backdrop-filter: saturate(200%) blur(25px);
}
</style>
  