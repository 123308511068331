import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL+'cmd/';

class CmdService {

  getQueued(bId, offset, limit, startDate = null, endDate = null) {
    return axios.get(API_URL + 'getqueued/' + bId, 
      { 
          params: {
              limit: limit,
              offset: offset,
              startDate: startDate,
              endDate: endDate
          },
          headers: authHeader()
      }
    );
  }

  getSent(bId, offset, limit, startDate = null, endDate = null) {
    return axios.get(API_URL + 'getsent/' + bId, 
      { 
          params: {
              limit: limit,
              offset: offset,
              startDate: startDate,
              endDate: endDate
          },
          headers: authHeader()
      }
    );
  }

  getRegistered() {
    return axios.get(API_URL + 'getregistered/', { headers: authHeader() });
  }

  getResponses(bId, offset, limit, startDate = null, endDate = null) {
    return axios.get(API_URL + 'getresponses/' + bId, 
      { 
          params: {
              limit: limit,
              offset: offset,
              startDate: startDate,
              endDate: endDate
          },
          headers: authHeader()
      }
    );
  }

  addCmd(bId, cmd) {
    return axios.put(API_URL + 'addcmd/' + bId, {
      cmd: cmd
    },
    { headers: authHeader() });
  }

  addCmdRegistered(cmd) {
    return axios.put(API_URL + 'addcmdregistered/', {
      cmd: cmd
    },
    { headers: authHeader() });
  }

}

export default new CmdService();