<template>
   <div class="container footercontainer">
      <footer class="pt-4 my-md-5 pt-md-5 border-top">
        <div class="row d-flex justify-content-center">
          <div class="col-12 col-md">
            <small class="d-block mb-3 text-muted">R42 &copy; 2023-2024</small>
          </div>
          <div class="col-3 col-md">
            <h5>Aide</h5>
            <ul class="list-unstyled text-small">
              <li><router-link class="text-muted" to="/faq">FAQ</router-link></li>
            </ul>
          </div>
          <div class="col-3 col-md">
            <h5>Contact</h5>
            <ul class="list-unstyled text-small">
              <li><router-link class="text-muted" to="/contact">Contact support</router-link></li>
            </ul>
          </div>
          <div class="col-3 col-md">
            <h5>Retrouvez-nous</h5>
            <ul class="list-unstyled text-small">
              <li><a class="text-muted" href="https://sms-active.fr/" target="_blank">SMS-active.fr</a></li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
</template>


<style>
.footercontainer {
  max-width: 960px;
}
</style>