<template>
    <DefaultHeader />

    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-12">
                <div class="card shadow my-2 p-3 bg-white rounded">
                    <div class="row d-flex justify-content-around align-items-center">
                        
                        <div class="col col-md-4 my-1">
                            <VehicleNameDisplay :balise="baliseInfo"></VehicleNameDisplay>
                        </div>
                        
                        <div class="col col-md-5 my-1">
                            <form>
                                <h5 class="text-center">Filtrer par date : </h5>
                                <div class="d-flex">
                                    <div class="input-group me-1">
                                        <span class="input-group-text bg-secondary-subtle">Début : </span>
                                        <input class="form-control" type="date" v-model="startDate" required>
                                    </div>
                                    <div class="input-group me-1">
                                        <span class="input-group-text bg-secondary-subtle">Fin : </span>
                                        <input class="form-control" type="date" v-model="endDate" required>
                                    </div>
                                    <button class="btn btn-primary" type="button" @click="balisePagesDrives() ; this.currentPage = 1; onPageChange(this.currentPage)">Ok</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-12 d-flex flex-column justify-content-between">
                <div class="card shadow mt-2 my-md-0 p-3 bg-white rounded">
                    <div class="d-flex justify-content-center">
                        <nav aria-label="...">
                            <Pagination 
                                :totalPages="totalPages" 
                                :perPage="perPage" 
                                :currentPage="currentPage" 
                                @pagechanged="onPageChange"
                            />
                        </nav>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Départ</th>
                                    <th scope="col" class="text-end">Arrivée</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(baliseDrive, index) in baliseDrives" :key="index">
                                    <td class="drive-row" colspan="2">
                                        <router-link :to="'/drivepositions/' + baliseDrive.id" class="text-decoration-none">
                                            <FullDriveDisplay :baliseDrive="baliseDrive" />
                                        </router-link>
                                    </td>
                                    <td style="width: 100px;" class="drive-row">
                                        <router-link :to="'/drivemap/' + baliseDrive.id" class="text-decoration-none">
                                            <img v-bind:src="require(`../assets/drivemap.png`)" class="rounded border border-info m-0 float-end"/>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <div class="row col-12 col-md-6">
                            <div class="d-flex justify-content-start">
                                <div class="col-8"><label for="resPerPage">Résultats par page :</label></div>
                                <div class="col-3"><select name="resPerPage" id="resPerPage" class="form-select"
                                        v-model="this.perPage"
                                        @change="setPerPage($event); this.currentPage = 1; onPageChange(this.currentPage)">
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                    </select></div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <nav aria-label="...">
                            <Pagination 
                                :totalPages="totalPages" 
                                :perPage="perPage" 
                                :currentPage="currentPage" 
                                @pagechanged="onPageChange"
                            />
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <DefaultFooter />
</template>

<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'
import FullDriveDisplay from '../components/display/FullDriveDisplay.vue'
import VehicleNameDisplay from '../components/display/VehicleNameDisplay.vue'
import DrivesService from '../services/drive.service'
import FormatHelper from '../helpers/format.helper.js'
import BaliseService from '../services/balise.service'
import Pagination from '../components/PaginationComponent.vue'


export default {
    components: {
        DefaultHeader,
        DefaultFooter,
        FullDriveDisplay,
        Pagination,
        VehicleNameDisplay

    },
    data() {
        return {
            baliseDrives: [],
            baliseInfo: [],
            drivesCount: null,
            pages: null,
            currentPage: 1,
            startResults: 0,
            perPage: 15,
            isEnd: false,
            displayEnd : false,
            startDate: null,
            endDate: null,
            enDateWithHours: null,
            totalPages: 0
        }
    },
    computed: {
        baliseIcon: function () {
            return {
                icon: this.baliseInfo.icon && require(`../assets/icons/${this.baliseInfo.icon}`)
            }
        }
    },
    mounted() {
        this.balisePagesDrives()
        this.getBaliseInfos()
    },
    methods: {
        balisePagesDrives() {
            this.baliseDrives = []
            if(this.endDate) {
                this.enDateWithHours = this.endDate+'T23:59:59'
            }
            DrivesService.getBaliseDrives(this.$route.params.id, this.startResults, this.perPage, this.startDate, this.endDateWithHours)
                .then(response => {
                    this.baliseDrives = FormatHelper.baliseDrivesFormat(response.data.rows)
                    this.drivesCount = response.data.count
                    this.totalPages = Math.ceil(this.drivesCount/this.perPage)
                    console.log(this.drivesCount)
                })
        },
        onPageChange(page){
            console.log(page)
            if (page > 1) {
                this.startResults = (page-1) * this.perPage
            }
            else {
                this.startResults = 0
            }
            this.baliseDrives = []
            DrivesService.getBaliseDrives(this.$route.params.id, this.startResults, this.perPage, this.startDate, this.endDateWithHours)
                .then(response => {
                    this.baliseDrives = FormatHelper.baliseDrivesFormat(response.data.rows)
                })
                this.totalPages = Math.ceil(this.drivesCount/this.perPage)
            this.currentPage = page
        },
        getBaliseInfos() {
            BaliseService.getBaliseInfo(this.$route.params.id)
                .then(response => {
                    this.baliseInfo = FormatHelper.baliseInfoFormat(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        },
        setPerPage(event) {
            this.perPage = event.target.value
            this.totalPages = Math.ceil(this.drivesCount/this.perPage)
        },
        getDuration(startDate, endDate) {

            console.log(typeof startDate)
            console.log(typeof endDate)
            //const timeDifference = new Date(startDate).getTime() - new Date(endDate).getTime();
            //const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

            return startDate;
        }
    }
}
</script>

<style>

.drive-row {
    cursor: pointer;
}

.drive-row:hover { 
   background: #d2edfa;
   border-radius: 10px;
   border-style: none;
}

</style>