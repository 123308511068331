<template>
    <span v-if="battery == null || battery == 'undefined'">
        N.C
    </span>
    <span v-else-if="!Number.isInteger(battery)">
        {{ battery }}
    </span>
    <span v-else-if="battery >= 50" class="badge text-nowrap bg-success fw-bold text-white align-baseline">
        <font-awesome-icon :icon="['fas', 'battery-full']" rotation=270 /> {{ battery }}%</span>
    <span v-else class="badge text-nowrap bg-danger fw-bold text-white align-bottom">
        <font-awesome-icon :icon="['fas', 'battery-quarter']" rotation=270 /> {{ battery }}%</span>
</template>

<script>
export default {
  props: ['battery']
}
</script>