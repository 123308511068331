<template>
    <div>
        <font-awesome-icon v-if="this.alert.alertTypeId == 1 || this.alert.alertTypeId == 2" :icon="['fas', 'location-pin']" class="text-center text-info" size="4x" />
        <font-awesome-icon v-else :icon="['fas', 'location-pin']" class="text-center red-icon" size="4x" />
        <span class="badge text-nowrap bg-white fw-bold align-baseline rounded-circle alert-icon">
        <font-awesome-icon v-if="alert.alertTypeId == 14" :icon="['fas', 'gauge-high']" class="text-center text-danger" size="2xl" />
        <font-awesome-icon v-else-if="alert.alertTypeId == 2" :icon="['fas', 'flag-checkered']" class="text-center text-black" size="2xl" />
        <font-awesome-icon v-else-if="alert.alertTypeId == 1" :icon="['fas', 'circle-play']" class="text-center green-icon" size="2xl" />
        <font-awesome-icon v-else :icon="['fas', 'triangle-exclamation']" class="text-center text-danger" size="2xl" />
        </span>
    </div>
</template>

<script>
export default {
  props: ['alert']
}
</script>

<style>
.alert-icon {
  position: absolute;
  top: 4px; left: 3px;
}

.green-icon {
  color: #13bb45;
}

.orange-icon {
  color: #ff9a03;
}

.red-icon {
  color: #ff0303;
}
</style>