<template>
    <div class="d-inline-flex align-items-center">
        <div>
            <div>
                Lat: 
                    <small v-if="latitude == null || latitude == 'undefined' || latitude == 0" class="text-muted">N.C</small>
                    <small v-else class="text-muted">{{ latitude }}</small>
            </div>
            <div>
                Long: 
                    <small v-if="longitude == null || longitude == 'undefined' || longitude == 0" class="text-muted">N.C</small>
                    <small class="text-muted">{{ longitude }}</small>
            </div>
        </div>
        <a :href="'https://www.google.fr/maps?q=' + latitude + ',' + longitude" target="_blank" rel="noopener noreferrer">
            <img v-bind:src="require(`../../assets/google-map.svg`)"/>
        </a>
    </div>
</template>

<script>
export default {
  props: ['latitude', 'longitude']
}
</script>